import { createStore } from "redux";

const INITIAL_STATE = {
  ordersuser: "",
  showNavbar: true,
  modules: { name: "Meu Pedi.do", id: 1 },
  dataQuery: {},
  globalColors: {},
  brandSettings: {},
};
//
function reducer(state = INITIAL_STATE, action) {
  if (action.type === "SET_ORDER_ID") {
    return {
      ...state,
      ordersuser: action.id,
    };
  }
  if (action.type === "SET_SHOW_NAVBAR") {
    return {
      ...state,
      showNavbar: action.status,
    };
  }
  if (action.type === "SET_DATA_QUERY") {
    return {
      ...state,
      dataQuery: action.data,
    };
  }
  if (action.type === "SET_GLOBAL_COLORS") {
    return {
      ...state,
      globalColors: action.data,
    };
  }
  if (action.type === "SET_BRAND_SETTINGS") {
    return {
      ...state,
      brandSettings: action.data,
    };
  }
  return state;
}

const store = createStore(reducer);
export default store;
