import styled, { css } from "styled-components";

const sizes = {
  small: css`
    height: 28px;
    font-size: 12px;
  `,
  default: css`
    height: 36px;
    font-size: 14px;
  `,
  big: css`
    height: 44px;
    font-size: 16px;
  `,
  biglg: css`
    height: 44px;
    font-size: 16px;
    width: 100%;
  `,
};
const colors = {
  default: css`
    background: transparent;
  `,
  primary: css`
    background: var(--dark);
  `,
  white: css`
    background: #fff;
  `,
  secondary: css`
    background: var(--blue);
  `,
  red: css`
    background: red;
  `,
};
const Button = styled.button.attrs({
  type: "button",
})`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  transition: filter 0.15s ease;
  background: var(--secondary-color);
  border: 0;
  padding: 0 15px;
  color: #fff;
  font-size: 12px;
  margin-left: ${(props) => props.ml || "0px"};
  margin-right: ${(props) => props.mr || "0px"};
  :hover {
    filter: brightness(0.9);
  }
  ${(props) => sizes[props.size || "default"]};
  ${(props) => colors[props.color || "default"]};
  ${(props) =>
    props.filled === false &&
    css`
      background: none;
      color: var(--dark);
      &:hover {
        background: none;
        opacity: 0.6;
      }
    `};
`;

export default Button;
