import styled, { css } from 'styled-components'

export const Container = styled.div`
    background-color: #fff;
    width: 95%;
    border-radius: 6px;
    height: calc(100vh - 20px);
    overflow-y: auto;
    overflow-x: hidden;
    margin: 10px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 35px;
    .logo {
        height: 60px;
        width: 100%;
        margin-bottom: 40px;
        padding: 15px 0;
        display: flex;
        justify-content: center;
        img {
            height: 100%;
        }
    }
    .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        .message_title {
            font-size: 16px;
            color: var(--dark);
        }
        span {
            font-size: 15px;
            color: ${(p) => p?.globalColors?.primary || 'var(--blue)'};
            text-align: center;
        }
    }
    .message_description {
        text-align: center;
        margin: 25px 0;
        font-size: 15px;
        color: var(--dark);
    }
    .rate {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 25px;
        span {
            text-align: center;
            font-size: 15px;
            margin-bottom: 5px;
        }
        .rs-rate {
            .rs-rate-character {
                :last-child {
                    margin-right: 0 !important;
                }
            }
            .rs-rate-character-full {
                div span {
                    background-color: ${(p) =>
                        p.globalColors?.primary || '#2196f3'} !important;
                }
            }
        }
        .label {
            width: 100%;
            display: flex;
            justify-content: space-between;
            span {
                font-size: 15px;
                color: var(--gray);
            }
        }
    }
    .rate-number {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        div {
            background: ${(p) => p?.globalColors?.primary || 'var(--blue)'};
        }
    }
    .actions {
        margin-top: 20px;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 5px;
        button {
            background: ${(p) => p?.globalColors?.primary || 'var(--blue)'};
            font-size: 15px;
            padding: 10px 25px;
            border-radius: 20px;
            font-weight: 400;
            color: var(--white);
            margin-bottom: 15px;
        }
        .message_thanks {
            font-size: 12px;
            font-weight: 400;
            text-decoration: underline;
            color: ${(p) => p?.globalColors?.primary || 'black'};
        }
        animation: showsbtn 800ms;
        @keyframes showsbtn {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }
    }
    animation: showss 800ms;
    @keyframes showss {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    ${(props) =>
        props.animale &&
        css`
    background: ${(p) => p?.globalColors?.background_color || 'white'};
    width: 100%;
    font-weight: 300;
    height: 100vh;
    margin: 0;
    padding-top: 0;
    border-radius: 0px;
    align-items: flex-start;
    .logo {
      background: ${(p) => p?.globalColors?.head_background_color || '#cdcdcd'};
      height: 60px;
      width: calc(100% + 80px);
      margin-left: -40px;
      margin-bottom: 40px;
      padding: 15px 0;
      display: flex;
      justify-content: center;
      img {
        height: 100%;
      }
    }
    .title {
      align-items: flex-start;
      .message_title {
        text-transform: uppercase;
        font-weight: 400;
      }
      span {
        text-align: left;
        font-weight: 300;
      }
    }
    .message_description {
      text-align: left;
    }
    .rate {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      margin-bottom: 25px;
      span {
        text-align: left;
      }
    }
    .rate-number {
        margin-right: -25px;
        margin-left: -25px;
        justify-content: flex-start;
        div {
            background: #cdcdcd;
            color: black;
            border-radius: 0;
        }

    }
    .actions {
        align-items: flex-start;
        text-transform: uppercase;
        button {
            text-transform: uppercase;
            font-weight: 400;
            border-radius: 0;
      }
  `}
`
export const Number = styled.div`
    min-width: 29px;
    min-height: 29px;
    margin: 0 3px 5px 0px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background: ${(p) => (p.active ? '#000000' : '#cdcdcd')} !important;
    color: ${(p) => (p.active ? '#fff' : '#000000')} !important;
    cursor: pointer;
    :hover {
        background: #000000 !important;
        color: white !important;
    }
`
