import React, { useEffect, useState } from 'react'
import { Rate } from 'rsuite'
import { Icon as IconFA } from '../../styles/components'
import {
    getBrands,
    getNpsProfile,
    getRecordsNps,
    setRateNps,
} from '../../services/api'
import { Container, Number } from './styles'
import { connect } from 'react-redux'
import { Input } from 'rsuite'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

function setShowNavbar(status) {
    return {
        type: 'SET_SHOW_NAVBAR',
        status: status,
    }
}
function setGlobalColors(data) {
    return {
        type: 'SET_GLOBAL_COLORS',
        data: data,
    }
}
function Rating({ dispatch, globalColors, match }) {
    let history = useHistory()
    const [data, setData] = useState([])
    const [profile, setProfile] = useState([])
    const [brand, setBrand] = useState(null)
    const [text, setText] = useState('')
    const [active, setActive] = useState(null)
    const [loading, setLoading] = useState(false)
    // eslint-disable-next-line no-unused-vars
    const [rates, setRates] = useState([])
    const handleChange = () => {
        setRateNps({
            _id: data?._id,
            status: 'answered',
            ratings: rates,
            rating_comment: text,
            answered_date: moment().format(),
        })
        history.push({
            pathname: '/finish',
            state: {
                message: profile?.answered_message,
                status: 'success',
                logo: brand?.logo[0]?.url,
            },
        })
    }
    useEffect(() => {
        setLoading(true)
        getRecordsNps(match.params.id).then((record) => {
            if (record) {
                setData(record)
                getBrands(record.hostname).then((brand) => {
                    setBrand(brand)
                    dispatch(
                        setGlobalColors({
                            primary: brand?.primary_color,
                            secondary: brand?.secondary_color,
                            background_color: brand?.background_color,
                            head_background_color: brand?.head_background_color,
                        })
                    )
                    getNpsProfile(record.nps_profile).then((profile) => {
                        if (record.status !== 'waiting') {
                            history.push({
                                pathname: '/finish',
                                state: {
                                    message: profile.data[0]?.expired_message,
                                    status: 'expired',
                                    logo: brand?.logo[0]?.url,
                                },
                            })
                        }
                        setProfile(profile.data[0])
                    })
                })
                setTimeout(() => {
                    setLoading(false)
                }, 300)
            }
        })
        dispatch(setShowNavbar(false))
    }, [])
    useEffect(() => {
        if (!data?._id) return
        setRateNps({
            _id: data._id,
            access_date: moment().format(),
        })
    }, [data])
    return (
        <Container animale={true} globalColors={globalColors}>
            {!loading && profile && (
                <>
                    <div className="logo">
                        <img src={brand?.logo[0]?.url || ''} alt="Logo" />
                    </div>
                    <div className="title">
                        <strong className="message_title">
                            {profile?.title}
                        </strong>
                        <span>{profile?.subtitle}</span>
                    </div>
                    <span className="message_description">
                        {profile?.welcome_message}
                    </span>
                    {profile?.ratings?.map((rate, index) => (
                        <div className="rate" key={index}>
                            <span className="title_question">{rate.name}</span>
                            <>
                                {profile?.range_type === 'unique' ? (
                                    <div>
                                        <Rate
                                            defaultValue={0}
                                            style={{ fontSize: 25 }}
                                            max={
                                                profile?.to_value -
                                                profile?.from_value +
                                                1
                                            }
                                            onChange={(e) => {
                                                if (
                                                    rates[index]?.name ===
                                                    rate.name
                                                ) {
                                                    rates[index] = {
                                                        name: rate.name,
                                                        value: e,
                                                    }
                                                } else {
                                                    rates.push({
                                                        name: rate.name,
                                                        value: e,
                                                    })
                                                }
                                            }}
                                            color={
                                                globalColors?.primary || 'blue'
                                            }
                                            character={
                                                <IconFA
                                                    icon={
                                                        profile?.icon?.split(
                                                            ' '
                                                        ) || ['fas', 'star']
                                                    }
                                                    width="24px"
                                                    height="24px"
                                                    color={
                                                        globalColors.primary ||
                                                        '#e5e5ea'
                                                    }
                                                />
                                            }
                                        />
                                        <div className="label">
                                            <span>{profile?.from_label}</span>
                                            <span>{profile?.to_label}</span>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        {!!rate?.icon && rate?.icon !== '' ? (
                                            <Rate
                                                defaultValue={0}
                                                style={{ fontSize: 25 }}
                                                max={
                                                    rate?.to_value -
                                                    rate?.from_value +
                                                    1
                                                }
                                                onChange={(e) => {
                                                    if (
                                                        rates[index]?.name ===
                                                        rate?.name
                                                    ) {
                                                        rates[index] = {
                                                            name: rate?.name,
                                                            value: e,
                                                        }
                                                    } else {
                                                        rates.push({
                                                            name: rate?.name,
                                                            value: e,
                                                        })
                                                    }
                                                }}
                                                color={
                                                    globalColors.primary ||
                                                    '#e5e5e5'
                                                }
                                                character={
                                                    <IconFA
                                                        icon={rate?.icon?.split(
                                                            ' '
                                                        )}
                                                        width="24px"
                                                        height="24px"
                                                        color={'#e5e5e5'}
                                                    />
                                                }
                                            />
                                        ) : (
                                            <div className="rate-number-width">
                                                <div className="rate-number">
                                                {rate &&
                                                    Array.from(
                                                        {
                                                            length:
                                                                rate?.to_value - (rate?.from_value - 1)
                                                        },
                                                        (value, key) => (
                                                            <Number
                                                                animale={true}
                                                                className={`evaluation_numbers`}
                                                                active={
                                                                    active
                                                                        ? key <=
                                                                          active
                                                                        : false
                                                                }
                                                                onClick={() => {
                                                                    setActive(
                                                                        key
                                                                    )
                                                                    let _rateIdx = rates.findIndex(e => e.name === rate.name);
                                                                    if (
                                                                        rates[
                                                                            _rateIdx
                                                                        ]
                                                                            ?.name ===
                                                                        rate.name
                                                                    ) {
                                                                        rates[
                                                                            _rateIdx
                                                                        ] = {
                                                                            name: rate.name,
                                                                            value: key,
                                                                        }
                                                                    } else {
                                                                        rates.push(
                                                                            {
                                                                                name: rate.name,
                                                                                value: key,
                                                                            }
                                                                        )
                                                                    }
                                                                }}
                                                            >
                                                                {rate?.from_value + key}
                                                            </Number>
                                                        )
                                                    )}
                                            </div>
                                            </div>
                                        )}
                                        <div className="label">
                                            <span>{rate?.from_label}</span>
                                            <span>{rate?.to_label}</span>
                                        </div>
                                    </>
                                )}
                            </>
                        </div>
                    ))}
                    {profile?.show_comment_field && (
                        <Input
                            componentClass="textarea"
                            rows={3}
                            placeholder="Compartilhe aqui alguma informação adicional que você queira : )"
                            onChange={(e) => setText(e)}
                            style={{ fontSize: 14, minHeight: 100 }}
                        />
                    )}
                    <div className="actions">
                        <button
                            className="button_send"
                            onClick={() => handleChange()}
                        >
                            Enviar avaliação
                        </button>
                        <strong
                            className="message_thanks"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                setRateNps({
                                    _id: data._id,
                                    status: 'skipped',
                                    answered_date: moment().format(),
                                })
                                history.push({
                                    pathname: '/finish',
                                    state: {
                                        message: profile?.skipped_message,
                                        status: 'skipped',
                                        logo: brand?.logo[0]?.url,
                                    },
                                })
                                dispatch(setShowNavbar(true))
                            }}
                        >
                            Obrigado, mas não quero avaliar
                        </strong>
                    </div>
                </>
            )}
        </Container>
    )
}

export default connect((state) => ({
    showNavbar: state?.showNavbar,
    globalColors: state?.globalColors,
    brandSettings: state?.brandSettings,
}))(Rating)
