import Home from './pages/Home'
import Detail from './pages/Details'
import DetailPackage from './pages/Package'
import Rating from './pages/Rating'
import Rating2 from './pages/Rating2'
import Wp from './pages/Wp'
import Finish from './pages/Finish'
import Devolution from './pages/Devolution'
import { Switch, Route, useLocation } from 'react-router-dom'
import Navbar from './pages/NavBar'
import { connect } from 'react-redux'
import PackageDevloutionDetail from './pages/Devolution/details'

const Routes = ({ showNavbar, brandSettings, globalColors }) => {
    const location = useLocation()

    const show =
        location.pathname !== '/returndetail' &&
        !location.pathname.includes('/devolutiondetail')
    const validNavbar = brandSettings.show_toolbar && showNavbar && show
    return (
        <div
            style={{
                maxWidth: 600,
                margin: '0 auto',
                height: validNavbar ? 'calc(100vh - 70px)' : '100vh',
                background: 'var(--background-color)',
                position: 'relative',
            }}
        >
            <Switch>
                <Route path="/wp/:id" component={Wp} exact />
                <Route
                    path="/devolutiondetail/:id"
                    component={PackageDevloutionDetail}
                    exact
                />
                <Route path="/ordersuser/:marca/:id" component={Home} />
                <Route path="/nps/:id" component={Rating} />
                <Route path="/nps2/:id" component={Rating2} />
                <Route path="/packagedetail/:id" component={DetailPackage} />
                <Route path="/returndetail" component={Devolution} />
                <Route path="/recordnpsuser" component={Rating} />
                <Route path="/finish" component={Finish} />
                <Route path="/:id" component={Detail} />
                <Route
                    component={() => (
                        <div
                            style={{
                                background: 'white',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100vh',
                            }}
                        >
                            <img
                                src="/images/app_package2.gif"
                                alt="icon"
                                style={{ height: 190 }}
                            />
                            <span>Building page...</span>
                        </div>
                    )}
                />
            </Switch>

            {validNavbar && <Navbar />}
        </div>
    )
}
export default connect((state) => ({
    showNavbar: state?.showNavbar,
    brandSettings: state?.brandSettings,
    globalColors: state?.globalColors,
}))(Routes)
