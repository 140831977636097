export function isMobile() {
    return /Mobi|Android/i.test(window.navigator.userAgent)
}
export function randomString(length = 10) {
    const caracteres =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let resultado = ''
    const comprimento = length

    for (let i = 0; i < comprimento; i++) {
        const indiceAleatorio = Math.floor(Math.random() * caracteres.length)
        resultado += caracteres.charAt(indiceAleatorio)
    }

    return resultado
}
