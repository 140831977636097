export function setOrderId(id) {
  return {
    type: "SET_ORDER_ID",
    id: id,
  };
}
export function setShowNavbar(status) {
  return {
    type: "SET_ORDER_ID",
    status: status,
  };
}
export function setDataQuery(data) {
  return {
    type: "SET_DATA_QUERY",
    data: data,
  };
}
export function setGlobalColors(data) {
  return {
    type: "SET_GLOBAL_COLORS",
    data: data,
  };
}
export function setBrandSettings(data) {
  return {
    type: "SET_BRAND_SETTINGS",
    data: data,
  };
}
