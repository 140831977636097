import React from 'react'
import { Container, Item } from './styles'
import { Link, useLocation } from 'react-router-dom'
import { Icon } from '../../styles/components'
import { connect } from 'react-redux'

function NavBar({ ordersuser, brandSettings, dataQuery }) {
    const nav = useLocation()
    const btn = [
        {
            name: 'Pedidos',
            icon: ['fas', 'bag-shopping-solid'],
            key: 1,
            path: `/ordersuser/${dataQuery?.marca}/${dataQuery.cpf}`,
            show: brandSettings.show_icon_orders,
        },
        {
            name: 'Devolução',
            icon: ['fas', 'redo-alt'],
            key: 2,
            path: '/returndetail',
            show: brandSettings.show_icon_return,
        },
        {
            name: 'Atendimento',
            icon: ['fas', 'user-headset'],
            key: 3,
            path: '/help',
            show: brandSettings.show_icon_helpdesk,
        },
        {
            name: 'Para você',
            icon: ['fas', 'heart'],
            key: 4,
            path: '/user',
            show: brandSettings.show_icon_favorites,
        },
    ]
    return (
        <Container>
            {btn
                .filter((e) => e?.show)
                .map((item) => (
                    <Link
                        to={item.path}
                        style={{ textDecoration: 'none', height: '100%' }}
                        key={item.key}
                    >
                        <Item
                            key={item.key}
                            active={nav?.pathname?.includes(item.path)}
                        >
                            <Icon
                                icon={item.icon}
                                width="20px"
                                height="20px"
                                style={{
                                    transform:
                                        item.icon[1] === 'redo-alt' &&
                                        'scaleX(-1)',
                                }}
                            />
                            <div className="label">{item.name}</div>
                        </Item>
                    </Link>
                ))}
        </Container>
    )
}

export default connect((state) => ({
    ordersuser: state?.ordersuser,
    brandSettings: state?.brandSettings,
    dataQuery: state?.dataQuery,
}))(NavBar)
