import React, { useEffect, useState } from 'react'
import { Container, Header, CardItems, Modal } from './styles'
import {
    getPackageDetail,
    getPackageStatus,
    getGlobalStatus,
} from '../../services/api'
import { connect } from 'react-redux'
import BackPage from '../../styles/components/backpage'
import { Loader } from '../../styles/components'
import TimeLine from './timeline'
function Details({ match }) {
    const [load, setLoad] = useState(false)
    const [status, setStatus] = useState([])

    const [show, setShow] = useState(false)
    const [img, setImg] = useState('')
    const [details, setDetails] = useState([])
    useEffect(() => {
        setLoad(true)
        getPackageStatus(match.params.id).then((res) => {
            setStatus(
                res?.[0]?.package_events
                    ?.filter((e) => e?.global_event_id)
                    ?.reduce((acc, item) => {
                        if (
                            !acc.some(
                                (el) =>
                                    el?.global_event_id ===
                                    item?.global_event_id
                            )
                        ) {
                            acc.push(item)
                        }
                        return acc
                    }, [])
            )
        })
        getPackageDetail(match.params.id).then((res) => {
            setDetails(res)
            setLoad(false)
        })
    }, [match.params.id])

    return (
        <Container>
            <div className="title">
                <BackPage
                    onClick={() => window.history.back()}
                    style={{ position: 'absolute', left: 16 }}
                />
                <strong>Detalhes do pacote</strong>
                <span />
            </div>
            {load ? (
                <Loader />
            ) : (
                <div className="container-animation">
                    <Header>
                        <div style={{ margin: '5px 0 15px' }}>
                            <strong>Pacote: {match.params.id}</strong>
                        </div>
                        <TimeLine data={status} />
                        <div style={{ marginBottom: 15 }}>
                            <strong>
                                {details?.itens?.length > 1 ? 'Itens' : 'Item'}{' '}
                                deste pacote:
                            </strong>
                        </div>
                        {details?.items?.map((item) => (
                            <CardItems
                                image={item?.imageUrl.replace(
                                    '55-83',
                                    '100-150'
                                )}
                                key={item._id}
                            >
                                <div
                                    className="img"
                                    onClick={() => {
                                        setImg(
                                            item?.imageUrl.replace(
                                                /130-190|100-150/g,
                                                '400-600'
                                            )
                                        )
                                        setShow(true)
                                    }}
                                />
                                <div className="info">
                                    <strong>{item?.name}</strong>
                                    <div className="row">
                                        <span>
                                            Quantidade: <b>{item.quantity}</b>
                                        </span>
                                        <strong>
                                            {Intl.NumberFormat('pt-BR', {
                                                style: 'currency',
                                                currency: 'BRL',
                                            }).format(item?.sellingPrice / 100)}
                                        </strong>
                                    </div>
                                </div>
                            </CardItems>
                        ))}
                    </Header>
                </div>
            )}
            {show && (
                <Modal>
                    <img src={`${img}`} alt="Look" />
                    <div className="overlay" onClick={() => setShow(false)} />
                </Modal>
            )}
        </Container>
    )
}

export default connect((state) => ({ ordersuser: state?.ordersuser }))(Details)
