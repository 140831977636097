import styled, { keyframes, css } from 'styled-components'

export const Container = styled.div`
    height: calc(100% - 4px);
    overflow: auto;
    .title {
        position: fixed;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        max-width: 600px;
        z-index: 9999;
        background: white;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 15px;
        height: 54px;
        flex: 1;
        font-size: 16px;
        img {
            position: absolute;
            left: 15px;
            width: 16px;
            height: 16px;
        }
        box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.12);
    }
    .container-animation {
        animation: show 400ms ease;
        margin-top: 50px;
        @keyframes show {
            from {
                transform: translateX(100px);
            }
            to {
                transform: translateX(0);
            }
        }
    }
`
export const Header = styled.div`
    padding: 15px;
    width: 100%;
    text-align: center;
    strong {
        text-align: center;
    }
`
export const CardItems = styled.div`
    display: flex;
    background: white;
    box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    height: 103px;
    margin-bottom: 15px;
    .img {
        height: 100%;
        width: 100px;
        background-image: url(${(props) => props.image}),
            url('/images/nophoto.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        position: relative;
        overflow: hidden;
        border-radius: 8px 0 0 8px;
        :hover {
            ::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: #00000050;
            }
        }
    }
    .info {
        font-size: 14px;
        flex: 1;
        padding: 10px;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        strong {
            text-align: left;
            font-size: 14px;
            font-weight: 400;
        }
        span {
            color: var(--gray);
        }
        b {
            color: var(--dark);
        }
        .row {
            display: flex;
            justify-content: space-between;
            &.row-1 {
                justify-content: flex-start;
                span {
                    margin-right: 15px;
                }
            }
        }
    }
`
export const Status = styled.div`
    font-size: 16px;
    width: 100%;
    strong {
        display: flex;
        justify-content: center;
    }
    .items {
        display: flex;
        flex-direction: column;
        padding: 15px 5px;
        div {
            font-size: 14px;
            margin-bottom: 15px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            span {
                font-weight: 400;
            }
            strong {
                font-weight: 400;
                color: var(--info);
                white-space: nowrap;
            }
        }
    }
`
export const Modal = styled.div`
    position: fixed;
    background: #00000090;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    top: 0;
    left: 0;
    img {
        z-index: 9999;
        max-width: 80%;
        border-radius: 4px;
        max-height: 100%;
    }
    .overlay {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #00000090;
        z-index: 998;
    }
`
// TIMELINE
export const TimeLine = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background: white;
    border-radius: 8px;
    box-shadow: ${({ slim }) =>
        slim ? '' : '0px 1px 14px rgba(0, 0, 0, 0.12)'};
    margin-bottom: 15px;
    padding: 15px;
    .timeline-item:last-child {
        margin-bottom: 0 !important;
    }
`
const items = 7
const duration = 700
const cycleGlyphs = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }
  75% {
    opacity: 0.5;
    transform: translateY(0);
  }
  100% {
    opacity: 1;
  }
`
function template(i, items, duration) {
    return `
      &:nth-child(${i + 1}) {
        animation-delay: ${`${(3000 / items) * i}ms`};
       }
    `
}
function getAnimations(items, duration) {
    let str = ''
    for (let i = 0; i < items; i += 1) {
        str += template(i, items, duration)
    }
    return str
}
export const TimeLineItem = styled.div`
    display: flex;
    align-items: center;
    transition: 300ms ease;
    animation: ${cycleGlyphs} ${duration}ms backwards;
    color: ${(props) => (props.active ? '#323338' : '#32333890')};
    font-size: 15px;
    margin-bottom: 20px;
    ${getAnimations(items, duration)}
    .icon {
        position: relative;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${(props) => (props.color ? props.color : 'transparent')};
        margin-right: 10px;
        border: 1px solid ${(props) => (props.active ? 'white' : '#bdc3c7')};
        span {
            background: ${(props) =>
                props.active ? 'white' : '#bdc3c7'} !important;
            width: 10px;
            height: 10px;
        }
        ${(props) =>
            props.before &&
            css`
                ::before {
                    content: '';
                    position: absolute;
                    top: calc(100% + 4px);
                    background: ${(props) =>
                        props.color ? props.color : '#323338'};
                    width: 2px;
                    height: 15px;
                    border-radius: 4px;
                }
            `}
    }
`
