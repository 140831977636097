import React from "react";
import { ThemeProvider } from "styled-components";
import { connect } from "react-redux";

const Theme = ({ children, globalColors }) => {
  return (
    <ThemeProvider theme={{ colors: globalColors }}>{children}</ThemeProvider>
  );
};

export default connect((state) => ({
  globalColors: state?.globalColors,
}))(Theme);
