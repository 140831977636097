import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  overflow-x: hidden;
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15px 15px 0;
    flex: 1;
    font-size: 18px;
    img {
      height: 50px;
      margin: 30px 0;
    }
    strong {
      margin-bottom: -15px;
    }
  }
`;
export const Cards = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  margin-top: 15px;
`;
export const CardItem = styled.div`
  font-size: 16px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  .row-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    strong {
      color: var(--dark);
      font-weight: 600;
    }
    span {
      color: var(--gray);
      font-weight: 300;
      font-weight: 300;
    }
  }
  .row-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    div {
      span {
        margin-right: 5px;
        color: var(--gray);
        font-weight: 300;
      }
      strong {
        color: var(--dark);
        font-weight: 600;
      }
    }
  }
  .row-detail {
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      border: 1px solid var(--primary-color);
      font-size: 14px;
      padding: 10px 15px;
      border-radius: 20px;
      font-weight: 400;
      background: transparent;
      color: var(--primary-color);
      transition: ease-out 0.4s;
      :hover {
        background: var(--primary-color);
        color: white;
      }
    }
    span {
      color: var(--green);
    }
  }
  .photo-package {
    height: 110px;
    width: 100%;
    background-image: url(${(props) => props.img});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin-bottom: 10px;
    border-radius: 4px;
    margin-top: 10px;
  }
  .status-pkg {
    background: ${(props) => props.color || "var(--blue)"};
    color: white;
    padding: 4px 15px;
    border-radius: 8px;
    text-align: center;
    font-size: 13px;
    font-weight: 600;
    width: fit-content;
  }
  animation: show 300ms ease;
  @keyframes show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
