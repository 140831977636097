import React, { useEffect, useState } from 'react'
import { CardItems } from './styles'
import { SelectPicker, Checkbox } from 'rsuite'
import { getReasons } from '../../services/api'
// import { Container } from './styles';

const style = {
    zIndex: 9999,
    fontSize: 14,
    margin: '0 auto',
}

function Card({ item, idx, setActiveButton, activeButton }) {
    const [items, setItems] = useState([])
    const [checked, setChecked] = useState(false)
    const [selectReason, setSelectReason] = useState([])
    const handleReason = (id) => {
        const idx = activeButton.findIndex((e) => e.uniqueId === item.uniqueId)
        activeButton[idx].reason = id
        if (idx !== -1) {
            setActiveButton(activeButton)
            const _data = items.find((e) => e._id === id).actions
            setSelectReason(_data)
        }
    }
    const handleAction = (id) => {
        const idx = activeButton.findIndex((e) => e.uniqueId === item.uniqueId)
        activeButton[idx].action = id
        if (idx !== -1) {
            setActiveButton(activeButton)
        }
    }
    const toggleCheck = (value) => {
        setChecked(value)
        if (value) {
            setActiveButton((current) => [...current, item])
        } else {
            setActiveButton((current) => {
                var idx = current.findIndex((e) => e.uniqueId === item.uniqueId)
                current.splice(idx, 1)
                return [...current]
            })
        }
    }
    useEffect(() => {
        getReasons().then((res) => setItems(res))
    }, [])
    return (
        <CardItems image={item.imageUrl} key={idx}>
            <Checkbox
                value={checked}
                onChange={(e, value) => toggleCheck(value)}
            />
            <div className="img"></div>
            <div className="details">
                <span
                    className="name"
                    style={{ whiteSpace: checked ? 'nowrap' : 'normal' }}
                >
                    {item.name}
                </span>
                <div className="info">
                    {checked && (
                        <>
                            <SelectPicker
                                placement="bottomStart"
                                data={items}
                                cleanable={false}
                                labelKey="name"
                                valueKey="_id"
                                appearance="subtle"
                                placeholder="O que aconteceu com este produto?"
                                searchable={false}
                                onChange={(e) => {
                                    handleReason(e)
                                }}
                                size="lg"
                                style={{ width: 'auto' }}
                                menuStyle={style}
                            />
                            <SelectPicker
                                placement="bottom"
                                data={selectReason}
                                labelKey="name"
                                disabled={selectReason.length === 0}
                                valueKey="_id"
                                cleanable={false}
                                appearance="subtle"
                                searchable={false}
                                onChange={(e) => {
                                    handleAction(e)
                                }}
                                placeholder="O que gostaria de fazer?"
                                size="lg"
                                style={{ width: 'auto' }}
                                menuStyle={style}
                            />
                        </>
                    )}
                </div>
            </div>
        </CardItems>
    )
}

export default Card
