import React from 'react'

import { Loading } from './style'

function Loader({ title }) {
    return (
        <Loading>
            <div className="lds-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <strong>{title ? title : 'Coletando informações'}</strong>
        </Loading>
    )
}

export default Loader
