import styled from 'styled-components'

export const Container = styled.div`
    position: fixed;
    max-width: 600px;
    bottom: 0;
    margin: 0 auto;
    background-color: white;
    min-height: 70px;
    width: 100%;
    left: auto;
    display: flex;
    justify-content: space-around;
    padding: 0 12px;
    align-items: center;
    z-index: 9998;
    filter: drop-shadow(0px -1px 12px rgba(0, 0, 0, 5%));
    a {
        height: 100%;
    }
`

export const Item = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    text-decoration: none;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    color: var(--dark);
    height: 100%;
    justify-content: center;
    .label {
        margin-top: 5px;
        color: ${(props) => (props.active ? 'black' : '#00000095')} !important;
    }
    span {
        text-decoration: none;
        white-space: nowrap;
        background: ${(props) =>
            props.active ? 'black' : '#00000050'} !important;
    }
`
