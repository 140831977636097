import axios from 'axios'
import moment from 'moment-timezone'
// PEGAR PEDIDOS DO USUÁRIO PARA DEVOLUÇÃO
export const getOrders = async ({ document, marca }) => {
    if (!document || !marca) return null
    return await list({
        datasource: 'pedidos',
        data: {
            filter: { 'clientProfileData.document': document, hostname: marca },
        },
    })
}
// PEGAR PEDIDO DO USUÁRIO
export const getOrder = async (code) => {
    let showSplash = false
    let order = await list({
        datasource: 'mp_orders',
        data: { filter: { short_code: code } },
    })
    order = order && order?.[0]
    if (!order) {
        return false
    }
    var data = {
        before_filter: { orderGroup: order?.orderGroup },
    }
    const pacote = await list({
        datasource: 'consulta_pacote_marca',
        data,
        type: 'query',
    })
    const userKey = localStorage.getItem('@mp_user_access_key')
    // Mesmo dispositivo acessando
    if (order?.access_code) {
        if (userKey === order?.user_access_key) {
            showSplash = false
        } else {
            showSplash = true
        }
    }
    return {
        order: pacote,
        showSplash: showSplash,
        splashData: order,
    }
}
export const consulta_pacotes_marca_itens = async (data) => {
    return await list({
        datasource: 'consulta_pacotes_marca_itens',
        data,
        type: 'query',
    })
}
// NEW API
export const getRecordsNps = async (id) => {
    console.log('🚀 ~ id:', id)
    if (!id) return null
    var data = {
        filter: { document_id: id, status: 'waiting' },
    }
    const res = await list({ datasource: 'nps_records', data })
    return res.length > 0 ? res[0] : null
}
// Atualizar NPS Records/Avaliação
export const setRateNps = async (data) => {
    return await update({ datasource: 'nps_records', data })
}
// Pegar informações para availiação
export const getNpsProfile = async (id) => {
    if (!id) return null

    const res = await list({
        datasource: 'nps_profile',
        data: { filter: { _id: id } },
    })
    return res || null
}
// Pegar pacotes para devolução
export const getPackagesDevolution = async (id) => {
    if (!id) return null
    const res = await list({
        datasource: 'pacotes',
        data: {
            filter: {
                userProfileId: id,
                $expr: {
                    $gte: [
                        { $toDate: '$date_limit_product_return' },
                        { $toDate: moment().format('YYYY-MM-DD') },
                    ],
                },
            },
        },
    })
    return res || null
}
export const getPackages = async ({ id, marca }) => {
    if (!id || !marca) return null
    const res = await list({
        datasource: 'pacotes',
        data: {
            filter: { document: id, marca: marca },
        },
    })
    return res || null
}
// Pegar motivos para devolução
export const getReasons = async () => {
    const res = await list({
        datasource: 'return_reason',
    })
    return res || null
}
// Pegar detalhes do pacote
export const getPackageDetail = async (id) => {
    if (!id) return null
    const res = await list({
        datasource: 'qry_pacote',
        type: 'query',
        data: { before_filter: { orderId: id } },
    })
    return (res && res[0]) || null
}
// PEGAR INFORMAÇÃO DA MARCA DA LOJA
export const getBrands = async (marca) => {
    if (marca) {
        var data = {
            filter: { nome_vtex: marca },
        }
        const res = await list({ datasource: 'mp_brands', data })
        return res[0]
    } else {
        return null
    }
}
// PEGAR TODOS OS PACOTES - TELA HOME - NEW API
export const getOrderUser = async ({ document, marca, filter = null }) => {
    if (!document || !marca) return null
    var data = {
        before_filter: {
            'clientProfileData.document': document,
            hostname: marca,
            ...filter,
        },
    }
    const res = await list({
        datasource: 'consulta_agrupado_pacote_marca',
        type: 'query',
        data,
    })
    return res
}
export const getPackageBrandConsult = async ({
    document,
    marca,
    filter = null,
}) => {
    if (!document || !marca) return null
    return await list({
        datasource: 'consulta_pacotes_marca',
        data: {
            before_filter: {
                'clientProfileData.document': document,
                hostname: marca,
                ...filter,
            },
        },
        type: 'query',
    })
}
// ------------------------- QUERY -------------------------
// PEGAR STATUS DO PACOTE EM TABELA EXTERNA
export const getPackageStatus = async (id) => {
    if (!id) return null
    return await list({
        datasource: 'consulta_pacote_marca',
        type: 'query',
        data: { before_filter: { orderId: id } },
    })
}
// PEGAR STATUS TABELA EXTERNA
export const getGlobalStatus = async () => {
    return await list({ datasource: 'mp_tracking_status' })
}
// ----------------------------------------------------------

export async function list({ datasource = '', data, type = 'table' }) {
    var config = {
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/api/${datasource}/filter?$type=${type}`,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN_MEUPEDIDO}`,
        },
        data: data,
    }

    return await axios(config)
        .then(function (response) {
            return response.data.data
        })
        .catch(function (error) {
            console.log(`Error ${datasource}...`, error)
            return null
        })
}
export async function show({ datasource = '', value, field = '_id' }) {
    var config = {
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/api/${datasource}/${value}?field=${field}`,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN_MEUPEDIDO}`,
        },
    }

    return await axios(config)
        .then(function (response) {
            return response.data.data
        })
        .catch(function (error) {
            console.log(`Error ${datasource}...`, error)
            return null
        })
}
export async function update({ datasource, data, idField = '_id' }) {
    return new Promise((resolve) => {
        let url = `${process.env.REACT_APP_API_URL}/api/${datasource}/${data?.[idField]}?field=${idField}`

        let config = {
            method: 'put',
            maxBodyLength: Infinity,
            url,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${process.env.REACT_APP_TOKEN_MEUPEDIDO}`,
            },
            data: data,
        }
        axios
            .request(config)
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                resolve(error.response.data)
            })
    })
}
export const pushItem = ({ datasource, idDocument, fieldItem, data = {} }) => {
    return new Promise((resolve) => {
        let url = `${process.env.REACT_APP_API_URL}/api/push/${datasource}`
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${process.env.REACT_APP_TOKEN_MEUPEDIDO}`,
            },
            url: url,
            data: {
                _id: idDocument,
                field: fieldItem,
                data,
            },
        }
        axios
            .request(config)
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                resolve(error.response.data)
            })
    })
}
