import moment from 'moment'
import React from 'react'
import { Icon } from '../../styles/components'
import { TimeLine, TimeLineItem } from './styles'

function Timeline({ data }) {
    return (
        <TimeLine>
            {data
                .sort((a, b) => {
                    if (a?.priority > b?.priority) {
                        return 1
                    }
                    if (a?.priority < b?.priority) {
                        return -1
                    }
                    return 0
                })
                .map((item, key) => (
                    <TimeLineItem
                        items={data?.length}
                        className="timeline-item"
                        color={item?.tracking_event?.color || '#bc32ac'}
                        key={item._id}
                        active={true}
                        before={key !== data.length - 1}
                    >
                        <div className="icon">
                            <Icon
                                icon={(
                                    item?.tracking_event?.icon || 'fas info'
                                )?.split(' ')}
                            />
                        </div>
                        <div className="flex justify-between flex-1">
                            <span>{item?.tracking_event?.name || '-'}</span>
                            <span>
                                {moment(item?.__created).format('DD/MM HH:mm')}
                            </span>
                        </div>
                    </TimeLineItem>
                ))}
        </TimeLine>
    )
}

export default Timeline
