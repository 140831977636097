import Home from './pages/Home'
import Detail from './pages/Details'
import DetailPackage from './pages/Package'
import Rating from './pages/Rating'
import Rating2 from './pages/Rating2'
import Wp from './pages/Wp'
import Finish from './pages/Finish'
import Devolution from './pages/Devolution'
import { Switch, Route, useLocation } from 'react-router-dom'
import Navbar from './pages/NavBar'
import { connect } from 'react-redux'
import PackageDevloutionDetail from './pages/Devolution/details'

const Routes = ({ showNavbar, brandSettings, globalColors }) => {
    const location = useLocation()

    const show =
        location.pathname !== '/returndetail' &&
        !location.pathname.includes('/devolutiondetail')
    const validNavbar = brandSettings.show_toolbar && showNavbar && show
    return (
        <div
            className="flex flex-col mx-auto relative h-screen"
            style={{
                maxWidth: 600,
                background: 'var(--background-color)',
            }}
        >
            <div
                className="flex w-full"
                style={{
                    height: `calc(100vh - ${validNavbar ? '70px' : '0px'})`,
                }}
            >
                <Switch>
                    <Route path="/wp/:id" component={Wp} exact />
                    <Route
                        path="/devolutiondetail/:id"
                        component={PackageDevloutionDetail}
                        exact
                    />
                    <Route path="/ordersuser/:marca/:id" component={Home} />
                    <Route path="/nps/:id" component={Rating} />
                    <Route path="/nps2/:id" component={Rating2} />
                    <Route
                        path="/packagedetail/:id"
                        component={DetailPackage}
                    />
                    <Route path="/returndetail" component={Devolution} />
                    <Route path="/recordnpsuser" component={Rating} />
                    <Route path="/finish" component={Finish} />
                    <Route path="/:id" component={Detail} />
                    <Route
                        component={() => (
                            <div className="w-full bg-white flex flex-col justify-center items-center h-screen">
                                <img
                                    src="/images/app_package2.gif"
                                    alt="icon"
                                    style={{ height: 190 }}
                                />
                                <span>App MeuPedi.do</span>
                            </div>
                        )}
                    />
                </Switch>
            </div>
            {validNavbar && <Navbar />}
        </div>
    )
}
export default connect((state) => ({
    showNavbar: state?.showNavbar,
    brandSettings: state?.brandSettings,
    globalColors: state?.globalColors,
}))(Routes)
