import moment from "moment";
import React from "react";
import { Icon } from "../../styles/components";
import { TimeLine, TimeLineItem } from "./styles";

function Timeline({ data }) {
  return (
    <TimeLine>
      {data.sort((a, b) => {
          if (a?.status_global_priority > b?.status_global_priority) {
            return 1;
          }
          if (a?.status_global_priority < b?.status_global_priority) {
            return -1;
          }
          return 0;
        }).map((item, key) => (
        <TimeLineItem
          className="timeline-item"
          color={item?.status_global_color}
          key={item._id}
          active={true}
          before={key !== (data.length - 1)}
        >
          <div className="icon">
            <Icon icon={item?.status_global_icon?.split(" ")} />
          </div>
          <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between'}}>
            <span>{item?.status_global_name}</span>
            <span>{moment(item.data).format("DD/MM HH:mm")}</span>
          </div>
        </TimeLineItem>
      ))}
    </TimeLine>
  );
}

export default Timeline;
