import React from 'react'
import { Icon } from '../../styles/components'

function Payment({ data }) {
    return (
        <div
            style={{
                borderBottom: 'thin solid var(--border2)',
                marginBottom: 6,
            }}
        >
            <div className="row">
                <span>Forma:</span>
                <strong>
                    {data.group === 'giftCard'
                        ? 'Vale presente'
                        : data.group === 'creditCard'
                        ? 'Cartão de crédito'
                        : data.group}
                </strong>
            </div>
            {data.group === 'creditCard' && (
                <div className="row">
                    <span>Pagamento:</span>
                    <strong style={{ display: 'flex' }}>
                        <Icon
                            icon={[
                                'fab',
                                data.paymentSystemName === 'Mastercard'
                                    ? 'cc-mastercard'
                                    : data.paymentSystemName === 'Visa'
                                    ? 'cc-visa'
                                    : data.paymentSystemName ===
                                      'Boleto Bancário'
                                    ? 'money-check-alt'
                                    : null,
                            ]}
                            color="#192061"
                        />
                        **** **** **** {data?.lastDigits || '****'}
                    </strong>
                </div>
            )}

            <div className="row">
                <span>Valor:</span>
                <strong>
                    {data?.value
                        ? Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                          }).format(data?.value / 100)
                        : '-'}
                </strong>
            </div>
        </div>
    )
}

export default Payment
