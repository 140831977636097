import React, { useState } from 'react'
import { ErrorContainer } from './style'
import { Button } from 'rsuite'
import { Link } from 'react-router-dom'
function Error({ children, id, setShowError }) {
    const [focus, setFocus] = useState(false)
    const [text, setText] = useState('')
    return (
        <ErrorContainer focus={focus}>
            <span>{children}</span>
            <span>{id}</span>
            <span>Informe abaixo um código para buscar novamente</span>
            <div className="input">
                <input
                    onChange={(e) => setText(e.target.value)}
                    placeholder="Código do produto"
                    onFocus={() => setFocus(true)}
                    onBlur={() => setFocus(false)}
                    spellCheck={false}
                />
            </div>
            <Link
                to={`/${text}`}
                style={{
                    width: '80%',
                    margin: '0 auto',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Button
                    onClick={() => setShowError(false)}
                    appearance="primary"
                    disabled={text === ''}
                    size="lg"
                    style={{ padding: '7px 30px' }}
                >
                    Procurar
                </Button>
            </Link>
        </ErrorContainer>
    )
}

export default Error
