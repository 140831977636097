import React from 'react'
import { ReturnItem, Status } from './styles'
import moment from 'moment'
import { Button } from 'rsuite'
import brands from './brands.json'

export default function DevolutionItems({ returnProducts, getPackages }) {
    return (
        <>
            <div
                style={{
                    marginTop: '54px',
                    height: 'calc(100vh - 104px)',
                    padding: '12px',
                    overflow: 'scroll',
                }}
            >
                {returnProducts?.length > 0 ? (
                    returnProducts?.map((pkg, idx) => (
                        <ReturnItem>
                            <div className="info">
                                <div className="info-text-list">
                                    <div className="info-text">
                                        <span>NF:</span> {pkg?.nf || '-'}
                                    </div>
                                </div>
                                <Status>{pkg?.status || 'Em aberto'}</Status>
                            </div>
                            <div className="info-text-row">
                                <div className="info-text">
                                    <span>
                                        {brands?.find(
                                            (e) => e?.nome_vtex === pkg?.brand
                                        )?.name || '-'}
                                    </span>
                                </div>
                                <div className="info-text">
                                    <span>Data:</span>{' '}
                                    {moment(pkg?.__created).format(
                                        'DD/MM/YY HH:mm'
                                    )}
                                </div>
                            </div>
                            <div className="imgs">
                                {pkg?.return_items.map((e) => (
                                    <img
                                        src={e.imageUrl}
                                        alt="img"
                                        style={{
                                            height: 90,
                                        }}
                                    />
                                ))}
                            </div>
                        </ReturnItem>
                    ))
                ) : (
                    <div
                        style={{
                            textAlign: 'center',
                            padding: 40,
                            fontSize: 16,
                        }}
                    >
                        Não há itens disponíveis que se encontram dentro do
                        prazo de devolução.
                    </div>
                )}
            </div>
            <div
                style={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    background: 'white',
                    zIndex: 9999,
                    display: 'flex',
                    padding: 9,
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Button
                    onClick={() => getPackages()}
                    appearance="primary"
                    size="lg"
                    style={{
                        width: 'auto',
                        margin: 'auto',
                    }}
                >
                    Nova troca/devolução
                </Button>
            </div>
        </>
    )
}
