import React, { useEffect, useState } from 'react'
import { Rate } from 'rsuite'
import { Icon as IconFA } from '../../styles/components'
import { getNpsProfile, setRateNps } from '../../services/api'
import { Container, Number } from './styles'
import { connect } from 'react-redux'
import { Input } from 'rsuite'
import moment from 'moment'

function setShowNavbar(status) {
    return {
        type: 'SET_SHOW_NAVBAR',
        status: status,
    }
}

function Rating({
    dispatch,
    setShowNps,
    npsData,
    globalColors,
    client,
    brandSettings,
}) {
    const [data, setData] = useState([])
    const [npsProfile, setNpsProfile] = useState([])
    const [text, setText] = useState('')
    const [active, setActive] = useState(null)
    const [loading, setLoading] = useState(false)
    // eslint-disable-next-line no-unused-vars
    const [rates, setRates] = useState([])

    useEffect(() => {
        setData(npsData)
        getNpsProfile(npsData?.nps_profile).then((res) => {
            if (res && res[0]) {
                setNpsProfile(res[0])
            }
        })
    }, [npsData])
    useEffect(() => {
        dispatch(setShowNavbar(false))
    }, [dispatch])
    const handleChange = () => {
        setLoading(true)
        setRateNps({
            _id: data?._id,
            status: 'answered',
            ratings: rates,
            rating_comment: text,
            answered_date: moment().format(),
        })
        setTimeout(() => {
            dispatch(setShowNavbar(true))
            setShowNps(false)
            setLoading(false)
        }, 1000)
    }
    useEffect(() => {
        if (!data?._id) return
        setRateNps({
            _id: data._id,
            access_date: moment().format(),
        })
    }, [data])
    return (
        <Container
            animale={true}
            globalColors={globalColors}
            image={`https://api.gruposoma.meupedi.do/modules/image/${data?.name}`}
        >
            {npsProfile && (
                <>
                    <div className="logo">
                        <img
                            src={brandSettings?.logo[0]?.url || ''}
                            alt="Logo"
                        />
                    </div>
                    <div className="title">
                        <strong className="message_title">
                            {npsProfile?.title}
                        </strong>
                        <span>{npsProfile?.subtitle}</span>
                    </div>
                    <span className="message_description">
                        {npsProfile?.welcome_message}
                    </span>
                    {npsProfile?.ratings?.map((rate, idx) => (
                        <div className="rate">
                            <span className="title_question">{rate.name}</span>
                            <div>
                                {npsProfile?.range_type === 'unique' ? (
                                    <div>
                                        <Rate
                                            defaultValue={0}
                                            style={{ fontSize: 25 }}
                                            max={
                                                npsProfile?.to_value -
                                                npsProfile?.from_value +
                                                1
                                            }
                                            onChange={(e) => {
                                                if (
                                                    rates[idx]?.name ===
                                                    rate.name
                                                ) {
                                                    rates[idx] = {
                                                        name: rate.name,
                                                        value: e,
                                                    }
                                                } else {
                                                    rates.push({
                                                        name: rate.name,
                                                        value: e,
                                                    })
                                                }
                                            }}
                                            color={
                                                globalColors?.primary || 'blue'
                                            }
                                            character={
                                                <IconFA
                                                    icon={
                                                        npsProfile?.icon?.split(
                                                            ' '
                                                        ) || ['fas', 'star']
                                                    }
                                                    width="24px"
                                                    height="24px"
                                                    color="#00000030"
                                                />
                                            }
                                        />
                                        <div className="label">
                                            <span>
                                                {npsProfile?.from_label}
                                            </span>
                                            <span>{npsProfile?.to_label}</span>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        {!!rate?.icon && rate?.icon !== '' ? (
                                            <Rate
                                                defaultValue={0}
                                                style={{ fontSize: 25 }}
                                                max={
                                                    rate?.to_value -
                                                    rate?.from_value +
                                                    1
                                                }
                                                onChange={(e) => {
                                                    if (
                                                        rates[idx]?.name ===
                                                        rate?.name
                                                    ) {
                                                        rates[idx] = {
                                                            name: rate?.name,
                                                            value: e,
                                                        }
                                                    } else {
                                                        rates.push({
                                                            name: rate?.name,
                                                            value: e,
                                                        })
                                                    }
                                                }}
                                                color="blue"
                                                character={
                                                    <IconFA
                                                        icon={rate?.icon?.split(
                                                            ' '
                                                        )}
                                                        width="24px"
                                                        height="24px"
                                                        color="#00000030"
                                                    />
                                                }
                                            />
                                        ) : (
                                            <div className="rate-number">
                                                {rate &&
                                                    Array.from(
                                                        {
                                                            length:
                                                                rate?.to_value -
                                                                (rate?.from_value -
                                                                    1),
                                                        },
                                                        (value, key) => (
                                                            <Number
                                                                animale={true}
                                                                className="evaluation_numbers"
                                                                active={
                                                                    active
                                                                        ? key <=
                                                                          active
                                                                        : false
                                                                }
                                                                onClick={() => {
                                                                    setActive(
                                                                        key
                                                                    )
                                                                    let _rateIdx =
                                                                        rates.findIndex(
                                                                            (
                                                                                e
                                                                            ) =>
                                                                                e.name ===
                                                                                rate.name
                                                                        )
                                                                    if (
                                                                        rates[
                                                                            _rateIdx
                                                                        ]
                                                                            ?.name ===
                                                                        rate.name
                                                                    ) {
                                                                        rates[
                                                                            _rateIdx
                                                                        ] = {
                                                                            name: rate.name,
                                                                            value: key,
                                                                        }
                                                                    } else {
                                                                        rates.push(
                                                                            {
                                                                                name: rate.name,
                                                                                value: key,
                                                                            }
                                                                        )
                                                                    }
                                                                }}
                                                            >
                                                                {rate?.from_value +
                                                                    key}
                                                            </Number>
                                                        )
                                                    )}
                                            </div>
                                        )}
                                        <div className="label">
                                            <span>{rate?.from_label}</span>
                                            <span>{rate?.to_label}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                    {npsProfile?.show_comment_field && (
                        <Input
                            className="textarea"
                            as="textarea"
                            rows={3}
                            placeholder="Compartilhe aqui alguma informação adicional que você queira : )"
                            onChange={(e) => setText(e)}
                            style={{ fontSize: 14, minHeight: 100 }}
                        />
                    )}
                    <div className="actions">
                        <button
                            className="button_send"
                            onClick={() => handleChange()}
                            disabled={loading}
                        >
                            {loading ? 'Enviando...' : 'Enviar avaliação'}
                        </button>
                        <strong
                            className="message_thanks"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                setRateNps({
                                    _id: data._id,
                                    status: 'skipped',
                                    answered_date: moment().format(),
                                })
                                dispatch(setShowNavbar(true))
                                setShowNps(false)
                            }}
                        >
                            Obrigado, mas não quero avaliar
                        </strong>
                    </div>
                </>
            )}
        </Container>
    )
}

export default connect((state) => ({
    showNavbar: state?.showNavbar,
    globalColors: state?.globalColors,
    brandSettings: state?.brandSettings,
}))(Rating)
