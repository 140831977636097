import React, { useEffect, useState } from 'react'
import { getPackageBrandConsult, list } from '../../services/api'
import { Container } from './styles'
import { Loader } from '../../styles/components'
import { connect } from 'react-redux'
import moment from 'moment'
import { CardItem } from '../Home/styles'
import { Link } from 'react-router-dom'
import BackPage from '../../styles/components/backpage'
import BgImage from '../../components/ImageBack'
import DevolutionItems from './devolution_items'

function Devolution({ ordersuser, dataQuery }) {
    const [packages, setPackages] = useState([])
    const [returnProducts, setReturnProducts] = useState([])
    const [load, setLoad] = useState(false)
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false)
    const getData = async () => {
        if (dataQuery.userprofileid) {
            const data = await list({
                datasource: 'return_records',
                data: {
                    filter: {
                        customerId: dataQuery.userprofileid,
                    },
                },
            })
            setReturnProducts(data || [])
            setLoad(false)
        } else {
            setLoad(false)
        }
    }
    const getPackages = async () => {
        setLoading(true)
        setShow(true)
        var minDate = moment().subtract(30, 'days').format('YYYY-MM-DD')
        const data = await getPackageBrandConsult({
            document: dataQuery.cpf,
            marca: dataQuery.marca,
            filter: {
                authorizedDate: { $gte: minDate },
            },
        })
        if (data?.length > 0) {
            setPackages(data)
            setLoading(false)
        } else {
            setLoading(false)
        }
    }
    // const getCalc = (idx, arrayData) => {
    //     // eslint-disable-next-line no-use-before-define
    //     const totalsOrder = arrayData || totalsOrder
    //     var total = 0
    //     for (let index = 0; index < totalsOrder.length; index++) {
    //         const element = totalsOrder[index]
    //         total += element[idx].value
    //     }
    //     return total
    // }
    // const getTotal = (arrayData) => {
    //     let total = 0
    //     for (let i = 0; i < 4; i++) {
    //         total += getCalc(i, arrayData)
    //     }
    //     return total / 100
    // }
    useEffect(() => {
        setLoad(true)
        getData()
    }, [ordersuser])
    return (
        <Container>
            <div className="title">
                <BackPage
                    onClick={() => {
                        if (show) {
                            setShow(false)
                        } else {
                            window.history.back()
                        }
                    }}
                />
                <div className="info">
                    <strong>Trocas e Devoluções</strong>
                    {show && packages?.length > 0 && (
                        <div className="text-info">
                            Confira abaixo o status de suas solicitações
                        </div>
                    )}
                </div>
                <span />
            </div>
            {load ? (
                <Loader />
            ) : !show ? (
                <DevolutionItems
                    returnProducts={returnProducts}
                    getPackages={getPackages}
                />
            ) : loading ? (
                <Loader />
            ) : (
                <>
                    <div
                        style={{
                            margin: '80px 0 65px',
                            padding: '0 12px',
                            overflow: 'hidden',
                        }}
                    >
                        {packages && packages.length > 0 ? (
                            <>
                                <strong
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        width: '100%',
                                        textAlign: 'center',
                                        fontSize: 16,
                                        fontWeight: 600,
                                        marginBottom: 16,
                                    }}
                                >
                                    Escolha abaixo os itens que gostaria de
                                    devolver.
                                </strong>
                                {packages.map((order, key) => (
                                    <CardItem key={order._id}>
                                        {/* {checkStatus(order.pacotes) && (
                                        <div className="row-name">
                                            <span>Status</span>
                                            <div className="status-pkg">
                                                {
                                                    checkStatus(order.pacotes)
                                                        ?.name
                                                }
                                            </div>
                                        </div>
                                    )} */}
                                        <div className="row-name">
                                            <span>Data da compra: {''}</span>
                                            <strong>
                                                {order?.authorizedDate
                                                    ? moment(
                                                          order?.authorizedDate
                                                      ).format('DD/MM/YYYY')
                                                    : '-'}
                                            </strong>
                                        </div>
                                        <div className="row-name">
                                            <span>Valor Total:</span>
                                            <strong>
                                                {Intl.NumberFormat('pt-BR', {
                                                    style: 'currency',
                                                    currency: 'BRL',
                                                }).format(
                                                    (order.value || 0) / 100
                                                )}
                                            </strong>
                                        </div>
                                        <BgImage
                                            url={`${process.env.REACT_APP_API_URL}/modules/image/${order.orderId}/100-150`}
                                        />
                                        <div className="photo-package" />
                                        <div className="row-detail">
                                            <Link
                                                to={{
                                                    pathname: `/devolutiondetail/${order?.orderId}`,
                                                    state: {
                                                        brand: order.hostname,
                                                        nf:
                                                            order
                                                                .packageAttachment
                                                                ?.packages
                                                                ?.length > 0
                                                                ? order
                                                                      .packageAttachment
                                                                      .packages[0]
                                                                      ?.invoiceNumber
                                                                : '',
                                                    },
                                                }}
                                            >
                                                <button type="button">
                                                    Trocar/Devolver
                                                </button>
                                            </Link>
                                            <span>{}</span>
                                        </div>
                                    </CardItem>
                                ))}
                            </>
                        ) : (
                            <div
                                style={{
                                    textAlign: 'center',
                                    padding: 40,
                                    fontSize: 16,
                                }}
                            >
                                Não há itens disponíveis que se encontram dentro
                                do prazo de devolução.
                            </div>
                        )}
                    </div>
                </>
            )}
        </Container>
    )
}

export default connect((state) => ({
    ordersuser: state?.ordersuser,
    dataQuery: state?.dataQuery,
}))(Devolution)
