import React, { Fragment } from 'react'
import GlobalStyle from './styles'
import Routes from './routes'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import moment from 'moment-timezone'
import store from './store'
import Theme from './styles/themeProvider'
moment.tz.setDefault('America/Fortaleza')

function App() {
    return (
        <Provider store={store}>
            <Theme>
                <BrowserRouter>
                    <Fragment>
                        <GlobalStyle />
                        <Routes />
                    </Fragment>
                </BrowserRouter>
            </Theme>
        </Provider>
    )
}

export default App
