import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Futura';
    src: url('fonts/Futura_Light_BT.ttf');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'Futura';
    src: url('fonts/Futura_Md_BT_Medium.ttf');
    font-weight: 400;
    font-style: normal;
  }
  * {
      --white: #fbfcfe;
      --dark: #222222;
      --blue: #2D9CDB;
      --gray: #828282;
      --gray2: #e9edf5;
      --green: #2AA952;
      --info: #314D64;
      --border1: #cfdbdf;
      --border2: #f4f4f4;
      --danger-color: #E74C3C;
      --primary-color: ${(props) => props?.theme?.colors?.primary};
      --secondary-color: ${(props) => props?.theme?.colors?.secondary};
      --background-color: ${(props) =>
          props?.theme?.colors?.background_color || '#e9edf5'};
      margin: 0;
      padding: 0;
      outline: 0;
      box-sizing: border-box;
  }
  body {
      font-family: "Nunito";
      text-rendering: optimizeLegibility !important;
      -webkit-font-smoothing: subpixel-antialiased !important;
      color: var(--dark);
      transition: 300ms;
      background: white;
  }
  
  input, button {
    font-family: "Nunito";
    -webkit-tap-highlight-color: transparent;
  }
  input,
  textarea,
  button,
  select,
  a {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    text-decoration: none !important;
    :hover {
      text-decoration: none !important;
    }
  }
  button {
    cursor: pointer;
  }
  .d-flex-center-full {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export default GlobalStyle
