import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Container, CardItem, CardPackage, CardPayment } from './styles'
import moment from 'moment-timezone'
import { Button } from 'rsuite'
import { Error } from '../../styles/components'
import { getOrder, getBrands, getGlobalStatus } from '../../services/api'
import { setDataQuery, setGlobalColors } from '../../store/actions'
import Detail from './detail'
import { connect } from 'react-redux'
import { Loader } from '../../styles/components'
import Payment from './payment'
import SplashUser from '../../components/SplashUser'
import { Modal } from 'antd'
import BgImage from '../../components/ImageBack'

function setBrandSettings(data) {
    return {
        type: 'SET_BRAND_SETTINGS',
        data: data,
    }
}
function Details({ match, ordersuser, brandSettings, dispatch }) {
    const [item, setItem] = useState([])
    const [showSplash, setShowSplash] = useState(false)
    const [splashData, setSplashData] = useState(null)
    const [globalStatus, setGlobalStatus] = useState([])
    const [payments, setPayments] = useState([])
    const [brand, setBrand] = useState({})
    const [totalsOrder, setTotalsOrder] = useState([])
    const [load, setLoad] = useState(false)
    const [showError, setShowError] = useState(false)

    const getCalc = (idx) => {
        var total = 0
        for (let index = 0; index < totalsOrder.length; index++) {
            const element = totalsOrder[index]
            total += element[idx].value
        }
        return total
    }
    const getTotal = () => {
        let total = 0
        for (let i = 0; i < 4; i++) {
            total += getCalc(i)
        }
        return total / 100
    }
    const _total = getTotal()

    useEffect(() => {
        if (!match.params.id) return
        setLoad(true)
        getOrder(match.params.id)
            .then(async ({ order, showSplash, splashData }) => {
                const _data = []
                if (splashData) {
                    setSplashData(splashData)
                }
                for (let item of order) {
                    _data.push(item.totals)
                }
                setTotalsOrder(_data)
                if (order[0].hostname) {
                    getBrands(order[0].hostname).then((brand) => {
                        setBrand(brand)
                        dispatch(setBrandSettings(brand))
                        const colors = {
                            primary: brand.primary_color,
                            secondary: brand.secondary_color,
                            background_color: brand.background_color,
                        }
                        dispatch(setGlobalColors(colors))
                    })
                }
                setItem(order)
                // Validacao para aparecer a tela que pede codigo de acesso para o usuario
                // if (showSplash) {
                //     setShowSplash(true)
                // }
                setTimeout(() => {
                    setLoad(false)
                }, 400)
            })
            .catch((err) => {
                setLoad(false)
                setShowError(true)
            })
        getGlobalStatus().then((res) => setGlobalStatus(res))
    }, [dispatch, match.params.id])
    useEffect(() => {
        if (!item) return
        if (!item[0]?.paymentData?.transactions) return
        setPayments(item[0]?.paymentData?.transactions[0]?.payments)
    }, [item])
    useEffect(() => {
        dispatch(
            setDataQuery({
                cpf: item[0]?.clientProfileData.document,
                marca: item[0]?.hostname,
                userprofileid: item[0]?.clientProfileData?.userProfileId,
                nome_cliente: item[0]?.clientProfileData?.firstName,
            })
        )
    })
    return load ? (
        <Loader />
    ) : showSplash ? (
        <Modal
            open={showSplash}
            closable={false}
            footer={null}
            centered
            styles={{
                content: { boxShadow: 'none' },
                wrapper: { background: 'white' },
            }}
        >
            <SplashUser
                brand={brand}
                onHide={() => setShowSplash(false)}
                splashData={splashData}
            />
        </Modal>
    ) : (
        <Container>
            <div className="title">
                <span />
                <strong className="text-base">Detalhes do pedido</strong>
                <span />
            </div>
            {load ? (
                <Loader />
            ) : showError ? (
                <Error
                    children="Não foi póssivel encontrar o pedido: "
                    id={match.params.id}
                    setShowError={setShowError}
                />
            ) : (
                <>
                    <div className="container-animation">
                        <div className="p-4 mt-10">
                            <CardItem>
                                {brand?.logo?.length > 0 && (
                                    <div className="w-full h-12 mb-4 flex justify-center">
                                        <img
                                            src={brand?.logo[0]?.url || ''}
                                            alt="Logo"
                                            className="h-full"
                                        />
                                    </div>
                                )}
                                <div className="row-date">
                                    <span>Data da compra:</span>
                                    <strong>
                                        {item[0]?.creationDate
                                            ? moment(
                                                  item[0]?.creationDate
                                              ).format('DD/MM/YYYY')
                                            : '-'}
                                    </strong>
                                </div>
                                <div className="row-name">
                                    <span>Valor total dos itens:</span>
                                    <strong>
                                        {_total
                                            ? Intl.NumberFormat('pt-BR', {
                                                  style: 'currency',
                                                  currency: 'BRL',
                                              }).format(_total)
                                            : '-'}
                                    </strong>
                                </div>
                            </CardItem>
                            {item?.length > 1 && (
                                <div className="label">
                                    <span style={{ fontSize: 15 }}>
                                        Para agilizarmos sua entrega, seu pedido
                                        foi dividido em:{' '}
                                        <b>{item?.length} envios</b>
                                    </span>
                                </div>
                            )}
                            {item?.sort().map((pkg, key) => (
                                <CardPackage
                                    key={pkg.orderId}
                                    color={
                                        pkg?.package_events?.at(-1)
                                            ?.tracking_event?.color
                                    }
                                >
                                    <div className="w-full flex justify-between items-center">
                                        <span>
                                            Pacote {key + 1} - {pkg.orderId}
                                        </span>
                                        {pkg?.package_events?.at(-1)
                                            ?.tracking_event && (
                                            <span className="status-pkg">
                                                {
                                                    pkg?.package_events?.at(-1)
                                                        ?.tracking_event?.name
                                                }
                                            </span>
                                        )}
                                    </div>
                                    <BgImage
                                        url={`${process.env.REACT_APP_API_URL}/modules/image/${pkg.orderId}`}
                                    />
                                    {item.length === 1 && (
                                        <Detail id={pkg.orderId} />
                                    )}
                                    <Link
                                        to={{
                                            pathname: `/packagedetail/${pkg.orderId}`,
                                            state: {
                                                id: pkg.orderId,
                                            },
                                        }}
                                    >
                                        <button>Mais informações</button>
                                    </Link>
                                </CardPackage>
                            ))}
                            <CardPayment>
                                <strong className="mb-2.5">
                                    Detalhes do pagamento
                                </strong>
                                {totalsOrder[0]?.map((e, key) => (
                                    <div className="row" key={e.id}>
                                        <span>{e.name}:</span>
                                        <strong>
                                            {getCalc(key)
                                                ? Intl.NumberFormat('pt-BR', {
                                                      style: 'currency',
                                                      currency: 'BRL',
                                                  }).format(getCalc(key) / 100)
                                                : '-'}
                                        </strong>
                                    </div>
                                ))}
                                <div
                                    className="row"
                                    style={{
                                        borderBottom:
                                            'thin solid var(--border2)',
                                        paddingBottom: 4,
                                    }}
                                >
                                    <span>Valor total dos itens:</span>
                                    <strong>
                                        {_total
                                            ? Intl.NumberFormat('pt-BR', {
                                                  style: 'currency',
                                                  currency: 'BRL',
                                              }).format(_total)
                                            : '-'}
                                    </strong>
                                </div>
                                {payments?.map((payment, key) => (
                                    <Payment key={key} data={payment} />
                                ))}
                            </CardPayment>
                            <CardPayment className="mt-4">
                                <strong className="mb-2.5">
                                    Endereço de entrega
                                </strong>
                                <div className="row">
                                    <span>Logradouro:</span>
                                    <strong className="text-right">
                                        {item[0]?.shippingData?.address?.street}
                                        ,{' '}
                                        {item[0]?.shippingData?.address?.number}
                                        {item[0]?.shippingData?.address
                                            ?.complement && (
                                            <>
                                                <br />
                                                {
                                                    item[0]?.shippingData
                                                        ?.address?.complement
                                                }
                                            </>
                                        )}
                                    </strong>
                                </div>
                                <div className="row">
                                    <span>Bairro:</span>
                                    <strong>
                                        {
                                            item[0]?.shippingData?.address
                                                ?.neighborhood
                                        }
                                    </strong>
                                </div>
                                <div className="row">
                                    <span>Cidade:</span>
                                    <strong>
                                        {item[0]?.shippingData?.address?.city} -{' '}
                                        {item[0]?.shippingData?.address?.state}
                                    </strong>
                                </div>
                                <div className="row">
                                    <span>CEP:</span>
                                    <strong>
                                        {
                                            item[0]?.shippingData?.address
                                                ?.postalCode
                                        }
                                    </strong>
                                </div>
                            </CardPayment>
                        </div>
                    </div>
                    {!brandSettings?.show_toolbar &&
                        brandSettings.show_icon_orders && (
                            <Link
                                to={`/ordersuser/${item[0]?.hostname}/${item[0]?.clientProfileData?.document}`}
                                className="btn-home"
                            >
                                <Button
                                    block
                                    size="lg"
                                    className="mx-auto text-white w-[95%]"
                                    style={{
                                        background: 'var(--primary-color)',
                                    }}
                                >
                                    Mostrar todos os pedidos
                                </Button>
                            </Link>
                        )}
                </>
            )}
        </Container>
    )
}

export default connect((state) => ({
    ordersuser: state?.ordersuser,
    brandSettings: state?.brandSettings,
}))(Details)
