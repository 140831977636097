import { useEffect, useState } from 'react'
import styled from 'styled-components'

const BgImage = ({ url, height = 110 }) => {
    const [imageSrc, setImageSrc] = useState(null)

    useEffect(() => {
        const fetchImage = async () => {
            try {
                const res = await fetch(url, {
                    mode: 'cors',
                })
                const blob = await res.blob()
                setImageSrc(URL.createObjectURL(blob))
            } catch (err) {
                console.error('Erro ao buscar imagem:', err)
            }
        }
        fetchImage()
    }, [])

    return <CardImage image={imageSrc} style={{ height }} />
}
export default BgImage

const CardImage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px 0;
    background-image: url(${(props) => props.image});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 100%;
    border-radius: 4px;
    position: relative;
`
