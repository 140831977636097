import React from 'react'
import Icon from './icon'

export default function BackPage({ onClick, style }) {
    return (
        <Icon
            onClick={onClick}
            icon={['fal', 'circle-chevron-left']}
            color="black"
            style={{ cursor: 'pointer', ...style }}
            width="26px"
            height="26px"
        />
    )
}
