import styled from 'styled-components'

const getUrlIcon = (icon) => {
    if (!icon || !Array.isArray(icon) || icon.length !== 2) return null
    switch (icon[0].slice(-1)) {
        case 'l':
            return `/svgs/light/${icon[1]}.svg`
        case 's':
            return `/svgs/solid/${icon[1]}.svg`
        case 'b':
            return `/svgs/brands/${icon[1]}.svg`

        default:
            return null
    }
}

const Icon = styled.span`
    display: inline-flex;
    width: ${({ width }) => width || '14px'};
    height: ${({ height }) => height || '14px'};
    mask-image: ${({ icon }) => `url(${getUrlIcon(icon)})`};
    mask-repeat: no-repeat;
    mask-size: 99% 99%;
    background: ${({ color }) => color || '#323338'};
    background-color: ${({ color, selected }) =>
        selected ? '#323338' : color} !important;
    animation: ${({ rotate }) => (rotate ? 'rotate 1s infinite linear' : '')};
    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }
`

export default Icon
