import React, { useEffect, useState } from 'react'
import { Button, Input, message } from 'antd'
import { pushItem, show, update } from '../../services/api'
import { randomString } from '../../services/util'
import moment from 'moment'

export default function SplashUser({ brand, onHide, splashData }) {
    const [messageApi, contextHolder] = message.useMessage()
    const [loading, setLoading] = useState(false)
    const [code, setCode] = useState(null)
    const onValidate = async () => {
        setLoading(true)
        const item = await show({
            datasource: 'mp_orders',
            field: 'access_code',
            value: code,
        })
        if (item?._id) {
            const userCode = randomString(10)
            localStorage.setItem('@mp_user_access_key', userCode)
            await update({
                datasource: 'mp_orders',
                data: { _id: item?._id, user_access_key: userCode },
            })
            onHide()
            pushItem({
                fieldItem: 'access_logs',
                idDocument: splashData?._id,
                data: {
                    code,
                    type: 'request_success',
                    __created: moment().format(),
                },
                datasource: 'mp_orders',
            })
        } else {
            pushItem({
                fieldItem: 'access_logs',
                idDocument: splashData?._id,
                data: {
                    code,
                    type: 'request_error',
                    __created: moment().format(),
                },
                datasource: 'mp_orders',
            })
            messageApi.error('Código invalido!')
        }
        setLoading(false)
    }

    useEffect(() => {
        if (!splashData?._id) return
        pushItem({
            fieldItem: 'access_logs',
            idDocument: splashData?._id,
            data: {
                type: 'access_request',
                __created: moment().format(),
            },
            datasource: 'mp_orders',
        })
        return () => {}
    }, [splashData])

    return (
        <div className="flex items-center justify-center flex-col px-8 gap-10 text-sm">
            {contextHolder}
            <div className="w-full h-12 mt-4 flex justify-center">
                {brand?.logo?.length > 0 && (
                    <img
                        src={brand?.logo[0]?.url || ''}
                        alt="Logo"
                        className="h-full"
                    />
                )}
            </div>
            <div className="flex flex-col items-center gap-2 text-center">
                <span>
                    Aqui na Animale, nos preocupamos muito com suas informações.
                </span>
                <span>
                    Para garantir que é você quem está acessando, enviamos um
                    código para o email:
                </span>
            </div>
            <div className="text-blue-800 mt-4 text-base">
                ric****@hotmail.com
            </div>
            <div className="flex flex-col gap-4 text-base text-center">
                <span>Informe abaixo o código recebido:</span>
                <Input.OTP
                    onChange={(v) => setCode(v)}
                    value={code}
                    type="number"
                    className="w-fit"
                    length={6}
                    size="large"
                />
            </div>
            <Button
                size="large"
                shape="round"
                type="primary"
                loading={loading}
                onClick={() => onValidate()}
            >
                Confirmar
            </Button>
        </div>
    )
}
