/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Container, Cards, CardItem } from './styles'
import { Link } from 'react-router-dom'
import { Loader } from '../../styles/components'
import moment from 'moment-timezone'
import NpsRecords from '../RatingHome'
import { getRecordsNps, getBrands, getOrderUser } from '../../services/api'
import { connect } from 'react-redux'

function setOrderId(id) {
    return {
        type: 'SET_ORDER_ID',
        id: id,
    }
}
function setGlobalColors(data) {
    return {
        type: 'SET_GLOBAL_COLORS',
        data: data,
    }
}

function Home({ match, dataQuery, dispatch }) {
    const [ordersUser, setOrdersUser] = useState([])
    const [load, setLoad] = useState([])
    const [brand, setBrand] = useState(null)
    const [showNps, setShowNps] = useState(false)
    const [error, setError] = useState(false)
    const [npsData, setNpsData] = useState([])
    const getCalc = (idx, arrayData) => {
        // eslint-disable-next-line no-use-before-define
        const totalsOrder = arrayData || totalsOrder
        var total = 0
        for (let index = 0; index < totalsOrder.length; index++) {
            const element = totalsOrder[index]
            total += element[idx].value
        }
        return total
    }
    const getTotal = (arrayData) => {
        let total = 0
        for (let i = 0; i < 4; i++) {
            total += getCalc(i, arrayData)
        }
        return total / 100
    }
    const checkStatus = (pkg) => {
        if (pkg[0].eventos[0]) {
            return {
                name: pkg[0].eventos[0]?.status_global_name,
                color: pkg[0].eventos[0]?.status_global_color,
            }
        }
        return null
    }
    useEffect(() => {
        getRecordsNps(match.params.id).then((res) => {
            if (res) {
                setNpsData(res)
                setShowNps(true)
            }
        })
    }, [match])
    useEffect(() => {
        setLoad(true)
        getOrderUser({
            document: match.params.id,
            marca: match.params.marca,
        }).then((res) => {
            setOrdersUser(res)
            setLoad(false)
        })
        getBrands(match.params.marca).then((res) => {
            if (!res) {
                setError(true)
                return
            }
            setBrand(res)
            dispatch(
                setGlobalColors({
                    primary: res.primary_color,
                    secondary: res.secondary_color,
                    background_color: res.background_color,
                })
            )
        })
    }, [])
    useEffect(() => {
        dispatch(setOrderId(match.params.id))
    }, [dispatch, match])
    if (error) {
        return (
            <div
                style={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    fontSize: 18,
                }}
            >
                Error :(
            </div>
        )
    }
    return showNps ? (
        <NpsRecords
            client={dataQuery?.nome_cliente}
            npsData={npsData}
            setShowNps={setShowNps}
            id={match.params.id}
        />
    ) : (
        <Container>
            <div className="title">
                <strong>Meus pedidos</strong>
                {brand?.logo?.length > 0 && (
                    <img src={brand?.logo[0]?.url || ''} alt="Logo" />
                )}
            </div>
            {brand && load ? (
                <>
                    <Loader />
                </>
            ) : (
                <>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            textTransform: 'uppercase',
                            fontSize: 16,
                        }}
                    >
                        Olá, {dataQuery?.nome_cliente}
                    </div>
                    <Cards>
                        {ordersUser
                            ?.sort((a, b) => {
                                if (
                                    moment(a.data_compra) <
                                    moment(b.data_compra)
                                ) {
                                    return 1
                                }
                                if (
                                    moment(a.data_compra) >
                                    moment(b.data_compra)
                                ) {
                                    return -1
                                }
                                return 0
                            })
                            .map((order, key) => (
                                <CardItem
                                    color={checkStatus(order.pacotes)?.color}
                                    key={order._id}
                                    img={`https://api.gruposoma.meupedi.do/modules/image/${order._id}/100-150`}
                                >
                                    {checkStatus(order.pacotes) && (
                                        <div className="row-name">
                                            <span>Status</span>
                                            <div className="status-pkg">
                                                {
                                                    checkStatus(order.pacotes)
                                                        ?.name
                                                }
                                            </div>
                                        </div>
                                    )}
                                    <div className="row-name">
                                        <span>Data da compra: {''}</span>
                                        <strong>
                                            {order.pacotes[0]?.authorizedDate
                                                ? moment(
                                                      order.pacotes[0]
                                                          ?.authorizedDate
                                                  ).format('DD/MM/YYYY')
                                                : '-'}
                                        </strong>
                                    </div>
                                    <div className="row-name">
                                        <span>Valor Total:</span>
                                        <strong>
                                            {Intl.NumberFormat('pt-BR', {
                                                style: 'currency',
                                                currency: 'BRL',
                                            }).format(
                                                getTotal(
                                                    order.pacotes.map(
                                                        (o) => o.totals
                                                    )
                                                )
                                            )}
                                        </strong>
                                    </div>
                                    <div className="photo-package" />
                                    <div className="row-detail">
                                        <Link
                                            to={{
                                                pathname: `/${order?._id}`,
                                                state: {
                                                    item: order,
                                                    id: order._id,
                                                },
                                            }}
                                        >
                                            <button type="button">
                                                Mais informações
                                            </button>
                                        </Link>
                                        <span>{}</span>
                                    </div>
                                </CardItem>
                            ))}
                    </Cards>
                </>
            )}
        </Container>
    )
}

export default connect((state) => ({
    ordersuser: state?.ordersuser,
    dataQuery: state?.dataQuery,
    globalColors: state?.globalColors,
}))(Home)
