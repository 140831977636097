import React, { useState } from 'react'
import { Number } from '../styles'
import styled from 'styled-components'

export default function Rate({ rate, rates, setRates }) {
    const [active, setActive] = useState(null)
    const [currentRate, setCurrentRate] = useState(null)
    const [optActive, setOptActive] = useState(null)

    function onChangeValue(field, value) {
        let _rateIdx = rates.findIndex((e) => e._id === rate._id)
        if (_rateIdx !== -1) {
            rates[_rateIdx][field] = value
        } else {
            rates.push({
                _id: rate._id,
                name: rate.name,
                [field]: value,
            })
        }
        setRates([...rates])
    }

    return (
        <div className="rate" key={rate._id}>
            <span className="title_question font-normal">{rate.name}</span>
            <div>
                <div className="rate-number">
                    {(rate?.custom || []).map((c, key) => (
                        <Number
                            key={key}
                            color={c?.color}
                            animale={true}
                            className="evaluation_numbers"
                            active={active === key}
                            onClick={() => {
                                setCurrentRate(c)
                                setActive(key)
                                onChangeValue('value', c?.label)
                            }}
                        >
                            {c?.label || '-'}
                        </Number>
                    ))}
                </div>
                <div className="label">
                    <span>{rate?.from_label}</span>
                    <span>{rate?.to_label}</span>
                </div>
                {currentRate?.options && (
                    <div className="flex gap-3 flex-wrap mt-4">
                        {currentRate?.options?.split(',').map((opt, i) => (
                            <Option
                                color={currentRate?.color}
                                key={`opt-${i}`}
                                onClick={() => {
                                    setOptActive(opt)
                                    onChangeValue('option', opt)
                                }}
                                className={`border ${
                                    optActive === opt ? 'active' : ''
                                }`}
                            >
                                {opt}
                            </Option>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

const Option = styled.div`
    border-color: ${({ color }) => color};
    color: ${({ color }) => color};
    padding: 8px;
    border-radius: 8px;
    font-weight: 500;
    font-size: 0.875rem;
    cursor: pointer;
    &:hover,
    &.active {
        background-color: ${({ color }) => color};
        color: white;
    }
`
