import React from 'react'
import { Container } from './styles'
import { connect } from 'react-redux'

function Finish({ location, globalColors }) {
    return (
        <Container status={location?.state?.status} globalColors={globalColors}>
            <div className="logo">
                <img src={location?.state?.logo} alt="logo" />
            </div>
            <div style={{ fontSize: 18 }}>
                {location?.state?.message || 'Message'}
            </div>
        </Container>
    )
}

export default connect((state) => ({
    globalColors: state?.globalColors,
}))(Finish)
