import axios from 'axios'
import { QRCodeCanvas } from 'qrcode.react'
import React, { useRef, useState } from 'react'
import { Button, Divider } from 'rsuite'
import styled from 'styled-components'
import { consulta_pacotes_marca_itens } from '../../services/api'
import BackPage from '../../styles/components/backpage'
import { Loader } from '../../styles/components'
import Card from './card'
import { Modal } from './styles'
import brands from './brands.json'
import Icon from '../../styles/components/icon'
export default function PackageDevloutionDetail({ match, location }) {
    const [activeButton, setActiveButton] = useState([])
    const [person, setPerson] = useState(null)
    const [load, setLoad] = useState(true)
    const [email2, setEmail2] = useState('')
    const [error, setError] = useState(false)
    const [response, setRespomse] = useState(null)
    const [open, setOpen] = useState(false)
    const [status, setStatus] = useState({
        name: 'Confirmando devolução',
        icon: ['fas', 'loader'],
        color: 'var(--blue)',
        rotate: true,
    })
    const [makeId, setMakeId] = useState(null)
    const qrRef = useRef()
    const [data, setData] = useState([])
    React.useEffect(() => {
        if (!match.params.id) return
        var data = {
            before_filter: { orderId: match.params.id },
        }
        consulta_pacotes_marca_itens(data).then((res) => {
            if (res !== null) {
                setData(res)
                setLoad(false)
                if (res.length > 0 && res[0]?.cliente) {
                    setPerson(res[0].cliente)
                }
                if (res.length > 0 && res[0]?.email2) {
                    setEmail2(res[0].email2)
                }
            }
        })
    }, [])
    function randomId(length) {
        var result = ''
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
        var charactersLength = characters.length
        for (var i = 0; i < length; i++) {
            result += characters.charAt(
                Math.floor(Math.random() * charactersLength)
            )
        }
        return result
    }
    async function sendItems() {
        setOpen(true)
        let canvas = qrRef.current.querySelector('canvas')
        canvas.toBlob(async (blob) => {
            let file = new File([blob], 'fileName.jpg', {
                type: 'image/jpeg',
            })
            const _file = await upload(file, 'fileName.jpg')
            if (_file?.name) {
                var fields = {
                    orderId: match.params.id,
                    customerId: person?.userprofileid,
                    customerEmail: person?.email,
                    email2: email2,
                    customerPhone: person?.phone,
                    name: `${person?.firstname} ${person?.lastname}`,
                    return_code_customer: makeId,
                    status: 'Em aberto',
                    nf: location?.state?.nf,
                    brand: location?.state?.brand,
                    brand_name:
                        brands.find(
                            (e) => e.nome_vtex === location?.state?.brand
                        )?.name || 'null',
                    qrCode: `https://gruposoma.meupedi.do${_file.url}`,
                    return_items: activeButton.map((e) => {
                        return {
                            uniqueId: e?.uniqueId,
                            return_type: e?.action || 'default',
                            return_reason: e?.reason || 'default',
                            imageUrl: e?.imageUrl || '',
                            name: e?.name || '',
                        }
                    }),
                }
                var config = {
                    method: 'post',
                    url: 'https://api.gruposoma.meupedi.do/exec_func',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${process.env.REACT_APP_TOKEN_MEUPEDIDO}`,
                    },
                    data: {
                        data: fields,
                        func: 'send_return_records',
                    },
                }
                await axios(config)
                    .then(function (response) {
                        setStatus({
                            name: 'Devolução concluída com sucesso',
                            color: 'green',
                            icon: ['fas', 'circle-check'],
                        })
                        setRespomse(response.data)
                        return response.data
                    })
                    .catch(function (error) {
                        setError(true)
                        setStatus({
                            name: 'Erro ao processar devolução',
                            color: 'red',
                            icon: ['fas', 'exclamation'],
                        })
                        console.log(error)
                    })
            } else {
                setStatus({
                    name: 'Erro ao processar devolução',
                    color: 'red',
                    icon: ['fas', 'exclamation'],
                })
                setError(true)
            }
        }, 'image/jpeg')
    }
    const upload = (file, hashname) => {
        return new Promise((accept, reject) => {
            var data = new FormData()
            data.append('file', file, hashname)
            data.append('dir', 'meupedidoapp')
            data.append('name', hashname)
            const config = {
                onUploadProgress: function (progressEvent) {
                    var percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    )
                },
            }
            axios
                .post(
                    'https://gruposoma.meupedi.do/file?baseUrl=http%3A%2F%2Fapi.local&project=&form=',
                    data,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${process.env.REACT_APP_TOKEN_MEUPEDIDO}`,
                        },
                    },
                    config
                )
                .then(async (res) => {
                    const js = res.data
                    const _md = {
                        storage: 'url',
                        name: js.originalname,
                        url: `/file${js.url}`,
                        size: js.size,
                        type: js.mimetype,
                        originalName: js.originalname,
                    }
                    accept(_md)
                })
                .catch(() => {
                    setStatus({
                        name: 'Erro ao processar devolução',
                        color: 'red',
                        icon: ['fas', 'exclamation'],
                    })
                    setError(true)
                    reject()
                })
        })
    }
    React.useEffect(() => {
        setMakeId(randomId(6))
    }, [activeButton])
    return (
        <Container>
            <div className="title">
                <BackPage onClick={() => window.history.back()} />

                <div className="info">
                    <strong>Trocas e Devoluções</strong>
                </div>
                <span />
            </div>
            {!load ? (
                !person ? (
                    <div className="error" style={{ fontSize: 18 }}>
                        Cliente não encontrado
                    </div>
                ) : (
                    <>
                        <div
                            ref={qrRef}
                            style={{
                                opacity: 0,
                                display: 'fixed',
                                pointerEvents: 'none',
                                position: 'absolute',
                            }}
                        >
                            <QRCodeCanvas value={makeId} />
                        </div>
                        <div
                            style={{
                                paddingTop: 12,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                            }}
                        >
                            {data?.map((pkg, idx) =>
                                pkg.items.map((e) => (
                                    <Card
                                        item={e}
                                        idx={idx}
                                        activeButton={activeButton}
                                        setActiveButton={setActiveButton}
                                    />
                                ))
                            )}
                        </div>
                        {activeButton.length > 0 && (
                            <div
                                style={{
                                    position: 'fixed',
                                    bottom: 0,
                                    left: 0,
                                    zIndex: 9998,
                                    width: '100%',
                                    padding: '12px',
                                }}
                            >
                                <Button
                                    appearance="primary"
                                    size="lg"
                                    style={{
                                        width: '95%',
                                        left: '2.5%',
                                    }}
                                    onClick={() => sendItems()}
                                >
                                    Confirmar troca/devolução dos itens
                                </Button>
                            </div>
                        )}
                    </>
                )
            ) : (
                <Loader title="Buscando pedidos..." />
            )}
            <Modal show={open}>
                <div className="body">
                    {status && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontWeight: 600,
                                fontSize: 16,
                            }}
                        >
                            <div
                                style={{
                                    marginRight: 12,
                                    width: 30,
                                    height: 30,
                                    borderRadius: 100,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    background: status.color,
                                }}
                            >
                                <Icon
                                    icon={status.icon}
                                    color="white"
                                    rotate={status?.rotate}
                                />
                            </div>
                            {status.name}
                        </div>
                    )}
                    {response && (
                        <div
                            className="keys-code"
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                textAlign: 'center',
                            }}
                        >
                            <Divider style={{ width: '80%' }} />O código da sua
                            devolução é:
                            <strong
                                style={{
                                    fontSize: 20,
                                    marginTop: 12,
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                                onClick={() => {
                                    navigator.clipboard.writeText(
                                        response?.data?.return_code_customer
                                    )
                                }}
                            >
                                {response?.data?.return_code_customer ||
                                    '000000'}
                            </strong>
                            Enviamos o comprovante de devolução para o email:
                            <strong>
                                {response?.data?.customerEmail ||
                                    'undefined@email.com'}
                            </strong>
                            <Button
                                onClick={() => window.history.back()}
                                style={{ marginTop: 18 }}
                            >
                                voltar ao inicio
                            </Button>
                        </div>
                    )}
                    {error && (
                        <Button
                            onClick={() => {
                                setOpen(false)
                                setStatus({
                                    name: 'Confirmando devolução',
                                    icon: ['fas', 'loader'],
                                    color: 'var(--blue)',
                                    rotate: true,
                                })
                                setLoad(false)
                                setError(false)
                            }}
                            style={{
                                marginTop: 18,
                                marginLeft: '50%',
                                transform: 'translateX(-50%)',
                            }}
                        >
                            voltar ao inicio
                        </Button>
                    )}
                </div>
            </Modal>
        </Container>
    )
}
const Container = styled.div`
    padding-top: 55px;
    height: 100vh;
    .title {
        position: fixed;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        width: 100%;
        max-width: 600px;
        background: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px;
        height: 54px;
        flex: 1;
        font-size: 18px;
        z-index: 9999;
        img {
            width: 16px;
            height: 16px;
        }
        box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.12);
        .info {
            strong {
                margin: 0;
            }
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            .text-info {
                font-size: 13px;
            }
        }
    }
    .error {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        white-space: nowrap;
        text-align: center;
        padding: 18px;
        color: #e74c3c;
        background: #e74c3c30;
        margin: 0 12px;
        border-radius: 12px;
    }
`
