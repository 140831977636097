import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 14px);
  overflow: auto;
  .title {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    z-index: 9999;
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    height: 54px;
    flex: 1;
    font-size: 18px;
    img {
      width: 16px;
      height: 16px;
    }
    box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.12);
  }
  .img-logo {
    margin: 10px auto;
    height: 80px;
  }
  .label {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 30px 50px;
  }
  .container-animation {
    animation: show 400ms ease;
    @keyframes show {
      from {
        transform: translateX(100px);
      }
      to {
        transform: translateX(0);
      }
    }
  }
  .btn-animation {
    animation: shows 400ms ease;
    @keyframes shows {
      from {
        transform: translateY(100px);
        opacity: 0;
      }
      to {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
  .btn-home {
    position: fixed;
    bottom: 0;
    background: white;
    width: 100%;
    max-width: 600px;
    padding: 10px 0 20px;
    border-radius: 4px 4px 0 0;
  }
`;
export const CardItem = styled.div`
  font-size: 16px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  .row-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    strong {
      color: var(--dark);
      font-weight: 700;
    }
    span {
      color: var(--gray);
      font-weight: 400;
    }
  }
  .row-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    div {
      span {
        margin-right: 5px;
        color: var(--gray);
        font-weight: 400;
      }
      strong {
        color: var(--dark);
        font-weight: 700;
      }
    }
  }
  .row-date {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    span {
      color: var(--gray);
      font-weight: 400;
    }
    strong {
      color: var(--dark);
      font-weight: 700;
    }
  }
`;
export const CardPackage = styled.div`
  font-family: "Nunito";
  font-size: 14px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 15px;
  .status-pkg {
    background: ${(props) => props.color || "var(--blue)"};
    color: white;
    padding: 4px 10px;
    border-radius: 8px;
    text-align: center;
    font-size: 12px;
  }
  span {
    color: var(--gray);
    font-weight: 600;
    margin-bottom: 5px;
  }
  .status {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0;
    width: 100%;
    div {
      font-size: 14px;
      margin-bottom: 5px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        font-weight: 400;
      }
      strong {
        font-weight: 400;
        color: var(--info);
        white-space: nowrap;
      }
    }
  }
  .photos {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px 0;
    background-image: url(${(props) => props.image});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 100%;
    height: 110px;
    border-radius: 4px;
    position: relative;
    /* :hover {
      ::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: #00000050;
      }
    } */
  }
  button {
    border: 1px solid var(--primary-color);
    font-size: 14px;
    padding: 10px 15px;
    border-radius: 20px;
    font-weight: 400;
    background: transparent;
    color: var(--primary-color);
    :hover {
      background: var(--primary-color);
      color: white;
    }
  }
`;
export const CardPayment = styled.div`
  font-family: "Nunito";
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  .row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
    span {
      white-space: nowrap;
      min-width: 120px;
      color: var(--gray);
      font-weight: 300;
      font-weight: 300;
    }
    strong {
      display: flex;
      align-items: center;
      color: var(--dark);
      font-weight: 600;
      span {
        min-width: 23px !important;
        height: 23px !important;
        margin-right: 10px;
      }
    }
  }
`;
