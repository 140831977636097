import styled from 'styled-components'

export const Container = styled.div`
    font-weight: 400;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: ${(p) => p.background_color};
    .logo {
        position: absolute;
        top: 0;
        background: ${(p) =>
            p?.globalColors?.head_background_color || '#cdcdcd'};
        height: 60px;
        width: 100%;
        margin-bottom: 40px;
        padding: 15px 0;
        display: flex;
        justify-content: center;
        img {
            height: 100%;
        }
    }
    div {
        text-align: center;
        color: ${(p) => p.primary || '#000'};
    }
`
