import styled from 'styled-components'

export const Container = styled.div`
    height: 100%;
    position: relative;
    animation: show 100ms;
    overflow: hidden;
    .title {
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        max-width: 600px;
        background: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px;
        height: 54px;
        flex: 1;
        font-size: 18px;
        z-index: 9999;
        img {
            width: 16px;
            height: 16px;
        }
        box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.12);
        .info {
            strong {
                margin: 0;
            }
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            .text-info {
                font-size: 13px;
            }
        }
    }
    @keyframes show {
        from {
            transform: translateX(100px);
        }
        to {
            transform: translateX(0);
        }
    }
`
export const Status = styled.div`
    color: white;
    background: #3498ff;
    padding: 4px 10px;
    border-radius: 8px;
    text-align: center;
    font-size: 12px;
`
export const CardItems = styled.div`
    display: flex;
    width: 95%;
    margin: 0 auto 15px;
    background: white;
    align-items: center;
    box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    padding: 10px;
    .img {
        height: 70px;
        width: 70px;
        min-height: 70px;
        min-width: 70px;
        background-image: url(${(props) => props.image});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        position: relative;
        overflow: hidden;
        border-radius: 8px;
        margin-right: 12px;
        display: flex;
    }
    .details {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .name {
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .info {
            font-size: 14px;
            display: flex;
            flex-direction: column;
            .rs-picker-toggle-caret {
                top: 5px;
            }
            .rs-picker-toggle-placeholder,
            .rs-picker-toggle-value {
                font-size: 14px;
            }
            .rs-picker-toggle {
                padding: 4px 0;
            }
        }
    }
    .rs-picker-disabled {
        opacity: 0.6;
    }
    .rs-checkbox-checked {
        label {
            color: #1675e0;
        }
    }
    .rs-checkbox-inner {
        ::before {
            background: white;
            border-radius: 100px;
            transform: scale(1.3);
        }
    }
    .rs-checkbox-checked {
        .rs-checkbox-inner {
            ::before {
                background-color: #3498ff;
            }
        }
    }
`
export const ReturnItem = styled.div`
    overflow: hidden;
    padding: 12px;
    margin: 8px 0;
    display: flex;
    border-radius: 4px;
    position: relative;
    flex-direction: column;
    background: #f3f3f5;
    box-shadow: 0px 1px 12px rgba(0, 0, 0, 5%);
    margin-bottom: 24px;
    .info {
        margin-bottom: 8px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .info-text-list {
            .info-text {
                margin-bottom: 8px;
                span {
                    font-weight: 600;
                }
            }
        }
    }
    .info-text-row {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .info-text {
            margin-bottom: 8px;
            span {
                font-weight: 600;
            }
        }
    }
    .imgs {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow-x: auto;
        max-width: 90%;
        margin: 0 auto;
        border-radius: 4px;
        margin-top: 4px;
    }
`
export const Modal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 9999;
    background-color: #00000030;
    display: ${({ show }) => (show ? 'flex' : 'none')};
    align-items: center;
    justify-content: center;
    overflow: hidden;
    .body {
        background-color: white;
        border-radius: 8px;
        z-index: 9999;
        width: 90%;
        height: auto;
        padding: 12px;
        z-index: 2;
        .keys-code {
            strong {
                margin: 12px 0;
                display: flex;
                .key {
                    width: 20px;
                    border-bottom: 1px solid #00000050;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 4px;
                }
            }
        }
    }
`
