import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Input } from 'rsuite'
import Rate from './components/Rate'
import { connect } from 'react-redux'
import { Container, Number } from './styles'
import BgImage from '../../components/ImageBack'
import { Icon as IconFA } from '../../styles/components'
import { getNpsProfile, setRateNps } from '../../services/api'

function setShowNavbar(status) {
    return {
        type: 'SET_SHOW_NAVBAR',
        status: status,
    }
}

function Rating({
    npsData,
    dispatch,
    setShowNps,
    ordersUser,
    globalColors,
    brandSettings,
}) {
    const [data, setData] = useState([])
    const [npsProfile, setNpsProfile] = useState([])
    console.log('🚀 ~ npsProfile:', npsProfile)
    const [text, setText] = useState('')
    const [activeRT, setActiveRT] = useState(null)
    const [loading, setLoading] = useState(false)
    const [rates, setRates] = useState([])
    const handleChange = () => {
        setLoading(true)
        setRateNps({
            _id: data?._id,
            status: 'answered',
            ratings: rates,
            rating_comment: text,
            answered_date: moment().format(),
        })
        setTimeout(() => {
            dispatch(setShowNavbar(true))
            setShowNps(false)
            setLoading(false)
        }, 1000)
    }
    useEffect(() => {
        setData(npsData)
        getNpsProfile(npsData?.nps_profile).then((res) => {
            if (res && res[0]) {
                setNpsProfile(res[0])
            }
        })
    }, [npsData])
    useEffect(() => {
        dispatch(setShowNavbar(false))
    }, [dispatch])
    useEffect(() => {
        if (!data?._id) return
        setRateNps({
            _id: data._id,
            access_date: moment().format(),
        })
    }, [data])

    if (!npsProfile) return

    return (
        <Container animale={true} globalColors={globalColors}>
            <div className="logo">
                <img src={brandSettings?.logo?.[0]?.url || ''} alt="logo" />
            </div>
            <div className="title mb-4">
                <strong className="message_title">{npsProfile?.title}</strong>
                <span>{npsProfile?.subtitle}</span>
            </div>
            <div className="mb-4 w-full overflow-x-auto">
                <BgImage
                    height={180}
                    url={`${process.env.REACT_APP_API_URL}/modules/image/${npsData?.name}/300-450`}
                />
            </div>
            {npsProfile?.welcome_message && (
                <span className="message_description">
                    {npsProfile?.welcome_message}
                </span>
            )}
            {npsProfile?.ratings_text && (
                <div className="rate">
                    <span className="title_question font-normal">
                        {npsProfile.ratings_text}
                    </span>
                    <div>
                        <div className="rate-number">
                            {Array.from(
                                Array(
                                    (npsProfile.ratings_to || 10) -
                                        (npsProfile.ratings_from - 1)
                                ).keys()
                            ).map((key) => (
                                <Number
                                    key={key}
                                    animale={true}
                                    className="evaluation_numbers"
                                    active={activeRT ? key <= activeRT : false}
                                    onClick={() => {
                                        setActiveRT(key)
                                        let _rateIdx = rates.findIndex(
                                            (e) => e._id === 'rttext'
                                        )
                                        if (_rateIdx !== -1) {
                                            rates[_rateIdx].value =
                                                npsProfile?.ratings_from + key
                                        } else {
                                            rates.push({
                                                _id: 'rttext',
                                                name: npsProfile.ratings_text,
                                                value:
                                                    npsProfile?.ratings_from +
                                                    key,
                                            })
                                        }
                                        setRates([...rates])
                                    }}
                                >
                                    {npsProfile?.ratings_from + key}
                                </Number>
                            ))}
                        </div>
                    </div>
                </div>
            )}
            {npsProfile?.ratings?.map((rate) => (
                <Rate rate={rate} rates={rates} setRates={setRates} />
            ))}
            {npsProfile?.show_comment_field && (
                <div className="flex flex-col gap-2">
                    <div className="text-black font-semibold">
                        OS DETALHES SÃO IMPORTANTES PARA NÓS. FIQUE A VONTADE
                        PARA FAZER QUALQUER COMENTÁRIO.
                    </div>
                    <Input
                        className="textarea"
                        as="textarea"
                        rows={3}
                        placeholder="Compartilhe aqui alguma informação adicional que você queira : )"
                        onChange={(e) => setText(e)}
                        style={{ fontSize: 14, minHeight: 100 }}
                    />
                </div>
            )}
            <div className="actions">
                <button
                    className="button_send"
                    onClick={() => handleChange()}
                    disabled={loading}
                >
                    {loading ? 'Enviando...' : 'Enviar avaliação'}
                </button>
                <strong
                    className="message_thanks"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        setRateNps({
                            _id: data._id,
                            status: 'skipped',
                            answered_date: moment().format(),
                        })
                        dispatch(setShowNavbar(true))
                        setShowNps(false)
                    }}
                >
                    Obrigado, mas não quero avaliar
                </strong>
            </div>
        </Container>
    )
}

const renderCharacter = (value, index, rate) => {
    const [type, icon] = (rate?.icon || 'fas star').split(' ')
    if (value < index + 1) {
        return (
            <IconFA
                icon={['fal', icon]}
                width="24px"
                height="24px"
                color="#00000030"
            />
        )
    }
    return <IconFA icon={[type, icon]} width="24px" height="24px" />
}

export default connect((state) => ({
    showNavbar: state?.showNavbar,
    globalColors: state?.globalColors,
    brandSettings: state?.brandSettings,
}))(Rating)
