import React, { useEffect, useState } from 'react'
import { Status } from '../Package/styles'
import { getPackageStatus } from '../../services/api'
import moment from 'moment-timezone'
function Details({ id }) {
    const [status, setStatus] = useState([])
    useEffect(() => {
        getPackageStatus(id).then((res) => setStatus(res[0].eventos))
    }, [id])
    return (
        <>
            {status?.length > 0 && (
                <div className="container-animation w-full">
                    <Status>
                        <strong>Status</strong>
                        <div className="items">
                            {status
                                .filter((e) => e.show_info_customer)
                                .sort((a, b) => {
                                    if (moment(a.data) > moment(b.data)) {
                                        return 1
                                    }
                                    if (moment(a.data) < moment(b.data)) {
                                        return -1
                                    }
                                    return 0
                                })
                                ?.map((status, i) => (
                                    <div key={i}>
                                        <span>{status.status_name}</span>
                                        <strong>
                                            {moment(status.data).format(
                                                'DD/MM HH:mm'
                                            )}
                                        </strong>
                                    </div>
                                ))}
                        </div>
                    </Status>
                </div>
            )}
        </>
    )
}

export default Details
